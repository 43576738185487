import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Typography } from "@mui/material";
import { json, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/userDashboard.scss";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {
  Box,
  Button,
  Paper,
  Avatar,
  IconButton,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Breadcrumbs,
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Popover,
  ListItemButton,
  TextareaAutosize
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowRight,
  faTrash,
  faPen,
  faGears,
  faCheck,
  faAngleUp,
  faEllipsisVertical,
  faArrowLeft,
  faThumbsUp,
  faHeart,
  faSmile,
  faAngry,
  faReply,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import PropTypes, { object } from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import logoutHelper from "../../../helpers/logoutHelper";
import Loader from "../../../components/common/loader";
import { Toaster } from "react-hot-toast";
import api from "../../../components/common/commonFunctionForApi";
import "../../../styles/userConversation.scss";
import { Toast } from "../../../utils/toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { faXmark, faFloppyDisk, } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Pusher from "pusher-js";
import { conversationAction } from "../../../redux/slices/conversation/conversation";
import TableHeaderForSorting from "../../../components/common/TableHeaderForSorting";
import { AntSwitch } from "../../../components/common/switch/antSwitch";
import noChatImg from "../../../assets/images/noChatImg.png"
import CryptoJS from 'crypto-js';
import { headerAction } from "../../../redux/slices/header/header";
import ChatSkleton from "../../../components/common/chat/ChatSkleton";
import UserListSkleton from "../../../components/common/chat/UserListSkleton";


function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="">{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  errorTab: PropTypes.number.isRequired,
};

const columns_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 120,
    className: "other-color",
  },
  {
    key: 2,
    id: "is_admin",
    disablePadding: true,
    label: "Admin",
    minWidth: 30,
    className: "other-color",
  },
  {
    key: 3,
    id: "remove",
    disablePadding: true,
    label: "Remove",
    minWidth: 30,
    className: "other-color",
  },
];

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function Conversation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Global search chat
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chatRoomId = searchParams.get('chat_room');
  const chatId = searchParams.get('chat_id');
  const [targetChatId, setTargetChatId] = useState(chatId || null);
  const [targetChatRoomId, setTargetChatRoomId] = useState(chatRoomId || null);
  const [isTargetMessageSeen, setIsTargetMessageSeen] = useState(false);

  const chatContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [openConversationModel, setOpenConversationModel] = useState(false)
  const [value, setValue] = useState(0);
  const [conversationType, setConversationType] = useState()
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [getFullUserList, setGetFullUserList] = useState([])
  const [getUserList, setGetUserList] = useState([])
  const [getAllGroupUsers, setGetAllGroupUsers] = useState([])

  const [selectedUser, setSelectedUser] = useState()
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroupUsers, setSelectedGroupUsers] = useState([])
  const [groupName, setGroupName] = useState()
  const [searchUsers, setSerachUsers] = useState()
  const [isSearchTyping, setIsSearchTyping] = useState(false)
  const [chatUserList, setChatUserList] = useState([])
  const [selectedChatRoom, setSelectedChatRoom] = useState(localStorage.getItem("selected-chat-room-id") ? (targetChatRoomId ? targetChatRoomId : localStorage.getItem("selected-chat-room-id")) : undefined)
  const [selectedChatRoomData, setSelectedChatRoomData] = useState()
  const [selectedChatRoomDataOnly, setSelectedChatRoomDataOnly] = useState([])
  const [typedMessage, setTypedMessage] = useState("")
  const [isTyping, setIsTyping] = useState(false)
  const [hasStartedTyping, setHasStartedTyping] = useState(false);


  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoadingMoreDown, setIsLoadingMoreDown] = useState(false);
  const [isLoadingMoreUp, setIsLoadingMoreUp] = useState(false);
  const [upPageCount, setUpPageCount] = useState(2);
  const [downPageCount, setDownPageCount] = useState(2);

  const [hasMoreMessages, setHasMoreMessages] = useState(selectedChatRoomData?.links?.next !== null);
  const [hasMoreMessagesUp, setHasMoreMessagesUp] = useState(true);
  const [hasMoreMessagesDown, setHasMoreMessagesDown] = useState(true);

  const [isLoadingMorePressed, setIsLoadingMorePressed] = useState(false)
  const [isEditConversation, setIsEditConversation] = useState(false)
  const [openLeaveConversationAlertModel, setOpenLeaveConversationAlertModel] = useState(false)
  const [openDeleteChatAlertModel, setOpenDeleteChatAlertModel] = useState(false)

  const [openAddGroupNameModel, setOpenGroupNameModel] = useState(false)

  const { pusherMessage, updatedPusherMessage, deletePusherMessage, reactionPusherMessage } = useSelector((state) => state?.header);

  const [startTyping, setStartTyping] = useState(null)
  const [stopTyping, setStopTyping] = useState(null)
  const [typingUsers, setTypingUsers] = useState([]);
  const [typindUsersData, setTypingUsersData] = useState([])
  const [pusherInstanceData, setPusherInstanceData] = useState()
  const [borderOnSendMessage, setBorderOnSendMessage] = useState(false)


  const [anchorElForStartConversation, setAnchorElForStartConversation] = useState(null);
  const [anchorElForEdit, setAnchorElForEdit] = useState(null);
  const [anchorElForChatEdit, setAnchorElForChatEdit] = useState(null);
  const [anchorElForReaction, setAnchorElForReaction] = useState(null);

  const openMenuForSatrtConversation = Boolean(anchorElForStartConversation)
  const openMenuForEdit = Boolean(anchorElForEdit)
  const openMenuForChatEdit = Boolean(anchorElForChatEdit)
  const openForReaction = Boolean(anchorElForReaction);

  const idStartConversation = openMenuForSatrtConversation ? "simple-popover" : undefined;
  const idForEdit = openMenuForEdit ? "simple-popover" : undefined;
  const idForChatEdit = openMenuForChatEdit ? "simple-popover" : undefined;
  const idForReaction = openForReaction ? "simple-popover" : undefined;

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);
  const [navigatedChatId, setNavigatedChatId] = useState(null);
  const [existingChatIds, setExistingChatIds] = useState([])
  const [missingChatIds, setMissingChatIds] = useState([])
  const [isChatSearching, setIsChatSearchTyping] = useState(false)
  const [searchChatMesagesData, setSearchChatMessageData] = useState()
  const [hasMoreSearchChatMessages, setHasMoreChatMessages] = useState(false)
  const [currentChatID, setCurrentChatID] = useState()
  const [isEditableChat, setIsEditableChat] = useState(false)
  const [editChatMsg, setEditChatMsg] = useState()
  const [isReacting, setIsReacting] = useState(false); // To track if a reaction is being selected
  const [isSelectedEdit, setIsSelectedEdit] = useState(false)
  const [anchorElForPopupUser, setAnchorElForPopupUser] = useState(null);
  const [reactionUsersList, setReactionUsersList] = useState([])

  const [isReplyQuote, setIsReplyQuote] = useState(false)
  const [replyQuoteID, setReplyQouteID] = useState()
  const [replyquoteUser, setReplyquoteUser] = useState()
  const [replyQuoteMessage, setReplyQuoteMessage] = useState()

  const [currentUserTypingData, setCurrentUserTypingData] = useState({});
  const [isChatRooomLoading, setIsChatRoomLoading] = useState(false);
  const [isUserListloading, setIsUserlistLoading] = useState(false);

  const [redirectionChatID, setRedirectionChatID] = useState()
  const [isRedirectionChatIDMissing, setISRedirectionChatIDMissing] = useState(false)

  const [popupPosition, setPopupPosition] = useState("top"); // "top" or "bottom"
  const moreVertRef = useRef(null);

  const openForPopupUser = Boolean(anchorElForPopupUser);
  const idPopupUser = openForPopupUser ? "simple-popover" : undefined;

  const chatContainerRef2 = useRef(null);
  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  const typingTimeoutRef = useRef(null);

  const jsonPusherData = localStorage.getItem("pusher")
  const pusherData = JSON.parse(jsonPusherData)

  const jsonTenantData = localStorage.getItem("tenant")
  const tenantData = JSON.parse(jsonTenantData)

  const selectedChatRoomID = localStorage.getItem("selected-chat-room-id")

  const devMode = process.env.REACT_APP_MODE
  let apiURL;

  if (devMode !== "DEV") {
    apiURL = `${window?.location?.protocol}//${window?.location?.hostname}/api`;
  } else {
    apiURL = `${window?.location?.protocol}//${window?.location?.hostname}:8000/api`;
  }

  let debounceTimer;


  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
    menuFor,
    adminAccessible
  } = useSelector((state) => state?.drawerMenu);

  const { anchorElForChat, anchorElForNotification, notificationFetched, notificationData, pusherInitialized } = useSelector((state) => state?.header);

  let selectedChatRoomLocal = localStorage.getItem("selected-chat-room-id")

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const userToken = localStorage.getItem("accessToken");

  const typeName = localStorage.getItem("type-selected-name-user");
  const typeID = localStorage.getItem("type-selected-id-user");
  const typeSlug = localStorage.getItem("type-selected-slug-user");

  const loggedInUserId = 5;

  const handleCloseConversationModel = () => {
    setOpenConversationModel(false)
    setConversationType()
    setSelectedUser()
    setIsEditConversation(false)
    setGroupName("")
    setSelectedGroupUsers()
    setConversationType()
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeForConversationType = (event) => {
    setConversationType(event.target.value);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert UNIX timestamp to milliseconds

    // Format the date as "30 Aug"
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    // Format the time as "12:45 pm"
    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toLowerCase();

    return `${day} ${month}, ${time}`;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert UNIX timestamp to milliseconds
    return date.toLocaleDateString(); // Display date in a readable format
  };

  const formatTypingMessage = () => {
    if (typingUsers.length === 0) return "";

    // Filter out the logged-in user from the typing users list
    const otherUsersTyping = typingUsers.filter(name => name !== loggedUser?.name);

    if (otherUsersTyping.length === 0) {
      return ""; // No one else is typing
    } else if (otherUsersTyping.length === 1) {
      return `${otherUsersTyping[0]} is typing...`;
    } else if (otherUsersTyping.length === 2) {
      return `${otherUsersTyping[0]} and ${otherUsersTyping[1]} are typing...`;
    } else {
      return `${otherUsersTyping.slice(0, -1).join(', ')}, and ${otherUsersTyping[otherUsersTyping.length - 1]} are typing...`;
    }
  };

  const formatTypingMessageOutput = () => {
    // Extract user names from currentUserTypingData
    const typingUserNames = Object.values(currentUserTypingData)
      .filter(user => user.id !== loggedUser?.id) // Exclude the logged-in user
      .map(user => user.name);

    // Generate the typing message based on the number of users typing
    if (typingUserNames.length === 0) return "";

    if (typingUserNames.length === 1) {
      return `${typingUserNames[0]} is typing...`;
    } else if (typingUserNames.length === 2) {
      return `${typingUserNames[0]} and ${typingUserNames[1]} are typing...`;
    } else {
      return `${typingUserNames.slice(0, -1).join(', ')}, and ${typingUserNames[typingUserNames.length - 1]} are typing...`;
    }
  };


  const [userTypingMessageData, setUserTypingMessageData] = useState("");

  const decryptData = (encData) => {
    // The APP_KEY in .env file. Note that it is base64 encoded binary
    var key = process.env.REACT_APP_DECRYPTION_KEY;

    // Laravel creates a JSON to store iv, value and a mac and base64 encodes it.
    // So let's base64 decode the string to get them.
    let decodedData = atob(encData);

    // Parse the decoded data as JSON
    let encrypted = JSON.parse(decodedData);

    // console.log("Laravel encryption result", encrypted);
    const iv = CryptoJS?.enc?.Base64?.parse(encrypted?.iv);
    const value = encrypted?.value;

    key = CryptoJS?.enc?.Base64?.parse(key);

    var decrypted = CryptoJS?.AES?.decrypt(value, key, {
      iv: iv
    });

    // CryptoJS returns a word array which can be
    // converted to string like this
    decrypted = decrypted?.toString(CryptoJS?.enc?.Utf8);

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  const handleToggle = (value, key) => {
    setSelectedGroupUsers((prevPermissions) =>
      prevPermissions?.map((permission) => {
        if (permission?.value === value) {

          // If any other permission is toggled, set deny to false
          return {
            ...permission,
            [key]: !permission[key],

          };
        }
        return permission;
      })
    );

    setGetAllGroupUsers((prevPermissions) =>
      prevPermissions.map((permission) => {
        if (permission?.value === value) {

          // If any other permission is toggled, set deny to false
          return {
            ...permission,
            [key]: !permission[key],
          };
        }
        return permission;
      })
    );
  };

  const removeGroupUser = (value) => {
    const users = selectedGroupUsers?.filter((user) => user?.value !== value)
    setSelectedGroupUsers(users)
  }

  const groupMessagesByDate = (messages) => {
    const groupedMessages = {};
    messages.forEach((message) => {
      const date = formatDate(message?.message_time);
      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }
      groupedMessages[date].push(message);
    });
    return groupedMessages;
  };

  const handlePopoverOpen = (event, chatID, editable, message) => {
    setIsEditableChat(editable)
    if (editable === true) {
      setEditChatMsg(message)
    }
    setAnchorElForReaction(event.currentTarget);
    setCurrentChatID(chatID);
  };

  const handlePopoverClose = () => {
    setAnchorElForReaction(null);
    setIsEditableChat(false)
    setEditChatMsg("")
    setCurrentChatID()
    setIsReacting(false);
  };

  const handlePopoverCloseForDataOnly = () => {
    if (isSelectedEdit === false) {
      setIsEditableChat(false)
      setEditChatMsg("")
      setCurrentChatID()
      setIsReacting(false);
    }

  };

  const handlePopoverOpenForUserReactionList = (e, reactions) => {
    setReactionUsersList(reactions)
    setAnchorElForPopupUser(e?.currentTarget);

  }

  const handlePopoverCloseForUserReactionList = () => {
    setAnchorElForPopupUser(null);
    setReactionUsersList([])
  };

  const handleReactionClick = (reactionType) => {
    setIsReacting(true);
    toggleReaction(currentChatID, reactionType, true);
    setTimeout(() => {
      setIsReacting(false); // Allow for some delay before resetting
    }, 100);
  };

  const handleClickForStartConversation = (event) => {
    handleCloseForEdit()
    if (anchorElForStartConversation === null) {
      setAnchorElForStartConversation(event.currentTarget);
    } else {
      setAnchorElForStartConversation(null);
    }
  };

  const handleCloseForStartConversation = () => {
    setAnchorElForStartConversation(null);
  };

  const handleClickForEdit = (event) => {
    handleCloseForStartConversation()
    if (anchorElForEdit === null) {
      setAnchorElForEdit(event.currentTarget);
    } else {
      setAnchorElForEdit(null);
    }
  };

  const handleCloseForEdit = () => {
    setAnchorElForEdit(null);
  };

  const handleCloseForReaction = () => {
    setAnchorElForReaction(null)
  }

  const handleClickForChatEdit = (event) => {
    handleCloseForStartConversation()
    setAnchorElForChatEdit(event.currentTarget);
  };

  const handleCloseForChatEdit = () => {
    setAnchorElForChatEdit(null);
  };

  const messageInputRef = useRef(null);

  const handleClickOnEditChat = (msg, editable, chatID) => {
    handlePopoverCloseForDataOnly()
    setTypedMessage(msg)
    setIsEditableChat(editable)
    setCurrentChatID(chatID);

    // Focus the input after setting the message
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }

  }

  const handleClickOnReply = (id, user, message) => {
    setIsReplyQuote(true)
    setReplyQouteID(id)
    setReplyquoteUser(user)
    setReplyQuoteMessage(message)
  }

  const handleCloseForReplyQuote = () => {
    setIsReplyQuote(false)
    setReplyQouteID()
    setReplyquoteUser()
    setReplyQuoteMessage()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior of Enter (adding a new line)
      if (typedMessage.trim()) {
        if (isEditableChat === true && currentChatID !== undefined) {
          updateMessage();
        } else {
          sendMessage();
        }
      }
    } else if (e.key === 'Enter' && e.shiftKey) {
      setBorderOnSendMessage(true)

      // Allow default behavior to add a new line when Shift+Enter is pressed
    }
  }

  const handleScrollToQuotedMessage = async (messageId) => {

    let targetMessage = document.getElementById(`chat-${messageId}`);
    if (targetMessage) {
      targetMessage.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Optionally, highlight the quoted message for better visibility
      targetMessage.classList.add('highlight-quoted-message');
      setTimeout(() => {
        targetMessage.classList.remove('highlight-quoted-message');
      }, 2000); // Remove highlight after 2 seconds
      setRedirectionChatID()
      setISRedirectionChatIDMissing(false)
    }
  };

  const getRelativeDate = (timestamp) => {
    const messageDate = new Date(timestamp * 1000);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isSameDay = (date1, date2) =>
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    if (isSameDay(messageDate, today)) {
      return 'Today';
    } else if (isSameDay(messageDate, yesterday)) {
      return 'Yesterday';
    } else {
      const weekDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const messageDay = weekDay[messageDate.getDay()];

      const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
      const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

      if (messageDate >= startOfWeek && messageDate <= endOfWeek) {
        return messageDay;
      } else {
        return messageDate.toLocaleDateString();
      }
    }
  };

  const groupMessagesByRelativeDate = (messages) => {
    const groupedMessages = {};
    messages?.forEach((message) => {
      const timestamp = message?.message_time;

      const messageDate = new Date(timestamp * 1000);

      if (isNaN(messageDate.getTime())) {
        console.error("Invalid Date for message_time:", timestamp);
        return; // Skip this message if the date is invalid
      }

      const today = new Date();
      const yesterday = new Date();
      yesterday?.setDate(today?.getDate() - 1);

      const startOfWeek = new Date(today);
      startOfWeek?.setDate(today?.getDate() - today?.getDay());

      const endOfWeek = new Date(today);
      endOfWeek?.setDate(today?.getDate() + (6 - today?.getDay()));

      const formatDate = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        return new Intl.DateTimeFormat('en-US', options)?.format(date);
      };

      const formatDateWithYear = (date) => {
        const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options)?.format(date);
      };

      let dateLabel;
      if (messageDate?.toDateString() === today?.toDateString()) {
        dateLabel = 'Today';
      } else if (messageDate?.toDateString() === yesterday?.toDateString()) {
        dateLabel = 'Yesterday';
      } else if (messageDate >= startOfWeek && messageDate <= endOfWeek) {
        dateLabel = formatDate(messageDate);
      } else if (messageDate?.getFullYear() === today?.getFullYear()) {
        dateLabel = formatDate(messageDate);
      } else {
        dateLabel = formatDateWithYear(messageDate);
      }

      const timeLabel = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      if (!groupedMessages[dateLabel]) {
        groupedMessages[dateLabel] = {};
      }

      if (!groupedMessages[dateLabel][timeLabel]) {
        groupedMessages[dateLabel][timeLabel] = [];
      }

      groupedMessages[dateLabel][timeLabel].push(message);
    });
    return groupedMessages;
  };

  const formatMessageTime = (timestamp) => {
    const messageDate = new Date(timestamp * 1000);
    const today = new Date();

    const isSameDay = (date1, date2) =>
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    if (isSameDay(messageDate, today)) {
      return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return messageDate.toLocaleDateString();
    }
  };

  const replaceMessageById = (messages, newMessage) => {
    return messages.map((msg) => (msg.id === newMessage.id ? newMessage : msg));
  };

  const handleMouseEnter = () => {
    if (moreVertRef.current) {
      const rect = moreVertRef.current.getBoundingClientRect();
      const spaceAbove = rect.top;
      const spaceBelow = window.innerHeight - rect.bottom;

      // Check which side has more space, default to 'top' if equal
      if (spaceBelow >= 120 || spaceBelow > spaceAbove) {
        setPopupPosition("bottom");
      } else {
        setPopupPosition("top");
      }
    }
  };

  const loadMoreMessages = async () => {
    if (!hasMoreMessages || isLoadingMore) return;

    setIsLoadingMore(true);
    setIsLoadingMorePressed(true)

    try {
      const response = await api.get(selectedChatRoomData?.links?.next, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity))

        // Add the new message to the array
        const updatedMessages = [...newMessages?.slice()?.reverse(), ...flattenedMessages];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
          links: response?.response?.links,
        }));

        // setSelectedChatRoomDataOnly((prev))

        setHasMoreMessages(response?.response?.links?.next !== null);
      } else if (response?.success === false) {
        // Toast.error(response?.data?.message);
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const loadMoreMessagesDown = async () => {
    if (!hasMoreMessagesDown || isLoadingMoreDown) return;

    setIsLoadingMoreDown(true);

    try {
      const response = await api.get(`/user/message-list/${selectedChatRoom}?message_id=${targetChatId}&page=${downPageCount}&order=down`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity))

        // Add the new message to the array
        const updatedMessages = [...flattenedMessages, ...newMessages?.slice()];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);


        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
          // links: response?.response?.links,
        }));


        setHasMoreMessagesDown(response?.response?.links?.next !== null);

        if (response?.response?.links?.next !== null) {
          setDownPageCount((prev) => prev + 1);
        }
      } else if (response?.success === false) {
        // Toast.error(response?.data?.message);
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMoreDown(false);
    }
  };

  const loadMoreMessagesUp = async () => {
    if (!hasMoreMessagesUp || isLoadingMoreUp) return;

    setIsLoadingMoreUp(true);

    try {
      const response = await api.get(`/user/message-list/${selectedChatRoom}?message_id=${targetChatId}&page=${upPageCount}&order=up`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity))

        // Add the new message to the array
        const updatedMessages = [...newMessages?.slice()?.reverse(), ...flattenedMessages];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);


        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
          // links: response?.response?.links,
        }));


        setHasMoreMessagesUp(response?.response?.links?.next !== null);
        if (response?.response?.links?.next !== null) {
          setUpPageCount((prev) => prev + 1);
        }
      } else if (response?.success === false) {
        // Toast.error(response?.data?.message);
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMoreUp(false);
    }
  };

  const loadMoreMessagesForSearch = async () => {
    if (!hasMoreMessages || isLoadingMore) return;

    setIsLoadingMore(true);
    setIsLoadingMorePressed(true);
    setLoading(true);

    try {
      const response = await api.get(selectedChatRoomData?.links?.next, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        setLoading(false);
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);

        // Flatten the existing messages structure
        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity));

        // Combine new and existing messages
        const updatedMessages = [...newMessages?.slice()?.reverse(), ...flattenedMessages];

        // Re-group messages by relative date
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        // Update state with new grouped messages
        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
          links: response?.response?.links,
        }));

        setHasMoreMessages(response?.response?.links?.next !== null);

        const newMessageIds = response?.response?.data?.map(chat => chat?.id);
        const combinedIds = [...flattenedMessages.map(chat => chat.id), ...newMessageIds];
        setExistingChatIds(combinedIds);

        const missingIds = missingChatIds.filter(id => !combinedIds.includes(id));
        if (missingIds?.length < 1) {
          setIsChatSearchTyping(false);
        }
        setMissingChatIds(missingIds);

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const loadMoreMessageDataForSearch = async () => {
    if (!hasMoreMessages) return;

    setLoading(true);

    try {
      const response = await api.get(`${searchChatMesagesData?.links?.next}&search=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        setLoading(false);

        const newMessageIds = response?.response?.data?.map(chat => chat?.id);
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);

        const combinedIds = [...existingChatIds, ...newMessageIds];
        setExistingChatIds(combinedIds);

        const missingIds = newMessageIds.filter(id => !combinedIds.includes(id));

        // Flatten the existing messages structure
        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity));

        // Combine new and existing messages
        const updatedMessages = [...newMessages?.slice()?.reverse(), ...flattenedMessages];

        // Re-group messages by relative date
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        // Update state with new grouped messages
        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
        }));

        setMissingChatIds(missingIds);

        if (missingIds?.length < 1) {
          const results = [];
          Object.keys(regroupedMessages).forEach(date => {
            Object.keys(regroupedMessages[date]).forEach(time => {
              regroupedMessages[date][time].forEach(chat => {
                if (chat.message.toLowerCase().includes(searchQuery.toLowerCase())) {
                  results.push(chat.id);
                }
              });
            });
          });

          setSearchResults(results);
          const lastIndex = results?.length - 1;
          setHasMoreChatMessages(response?.response?.links?.next !== null);

          // Navigate to the last result if it exists
          if (lastIndex >= 0) {
            setCurrentHighlightIndex(lastIndex);
            setNavigatedChatId(results[lastIndex]);
          } else {
            setCurrentHighlightIndex(0);
            setNavigatedChatId(null);
          }
        }

        setSearchChatMessageData({
          data: regroupedMessages,
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details,
        });

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const loadMoreMessagesForRedirection = async () => {
    if (!redirectionChatID || isRedirectionChatIDMissing === false) return;

    try {
      const response = await api.get(selectedChatRoomData?.links?.next, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        let newMessages = response?.response?.data;
        newMessages = newMessages.flat(Infinity);

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat(Infinity))

        // Add the new message to the array
        const updatedMessages = [...newMessages?.slice()?.reverse(), ...flattenedMessages];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: regroupedMessages,
          links: response?.response?.links,
        }));

        setHasMoreMessages(response?.response?.links?.next !== null);

        const isChatIdMissing = response?.response?.data?.filter((data) => data?.id === redirectionChatID)

        if (isChatIdMissing?.length < 1) {
          setISRedirectionChatIDMissing(true)
        } else {
          setISRedirectionChatIDMissing(false)
        }

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.error("Error loading more messages:", err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const fetchUnreadMsgCount = async () => {
    try {
      const response = await api.get(`user/unread-message-count`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setLoading(false)
        dispatch(conversationAction?.setUnReadMessageCount(response?.response?.unread_message_count))

      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  }

  const manageChatData = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id === pusherMessage?.chat_room_id)

    if (selectedChatRoom === pusherMessage?.chat_room_id && isChatRoomExist) {
      const newMessage = pusherMessage;
      const flattenedMessages = Object.values(selectedChatRoomData?.data)
        .flatMap(group => Object.values(group).flat());

      // Add the new message to the array
      const updatedMessages = [...flattenedMessages, newMessage];

      // Re-group messages
      const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

      // Update your state or data structure here
      setSelectedChatRoomData({
        ...selectedChatRoomData,
        data: regroupedMessages
      });

      setChatUserList(prevState => {
        const updatedList = prevState.map(chatUser => {
          if (chatUser?.chat_room_id === selectedChatRoom) {
            return {
              ...chatUser,
              last_message_sender: pusherMessage?.sender_detail?.full_name,
              last_message: pusherMessage?.message,
              message_time: pusherMessage?.message_time,
              last_message_id: pusherMessage?.id,
            };
          }
          return chatUser;
        });

        // Move the updated chat room to the top
        const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
        if (chatUserIndex > -1) {
          const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
          updatedList.unshift(updatedChatUser);
        }

        return updatedList;
      });
    }
    else if (selectedChatRoom != pusherMessage?.chat_room_id && isChatRoomExist) {

      const newMessage = pusherMessage;

      setChatUserList(prevState => {
        const updatedList = prevState.map(chatUser => {
          if (chatUser?.chat_room_id === pusherMessage?.chat_room_id) {
            return {
              ...chatUser,
              last_message_sender: pusherMessage?.sender_detail?.full_name,
              last_message: pusherMessage?.message,
              unread_count: chatUser?.chat_room_id === selectedChatRoom ? 0 : chatUser?.unread_count + 1,
              message_time: pusherMessage?.message_time,
              last_message_id: pusherMessage?.id
            };
          }
          return chatUser;
        });

        // Move the updated chat room to the top
        const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === pusherMessage?.chat_room_id);
        if (chatUserIndex > -1) {
          const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
          updatedList.unshift(updatedChatUser);
        }

        return updatedList;
      });

    } else if (!isChatRoomExist && pusherMessage?.message != null) {
      fetchAllChatRoomList()
    }
  }

  const startTypingData = async () => {
    let data = {
      chat_room: selectedChatRoom
    }
    try {
      setLoading(false);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/user-typing/`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setLoading(false);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in start typing::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        handlePopoverClose()

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const stopTypingData = async () => {
    let data = {
      chat_room: selectedChatRoom
    }
    try {
      setLoading(false);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/stopped-typing/`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setLoading(false);
      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in start typing::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        handlePopoverClose()

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const manageUpdatedChatData = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id === updatedPusherMessage?.chat_room_id)
    if (selectedChatRoom === updatedPusherMessage?.chat_room_id && isChatRoomExist) {
      const updatedMessages = replaceMessageById(Object.values(selectedChatRoomData?.data)
        .flatMap(group => Object.values(group).flat()), updatedPusherMessage);

      const groupedMessages = groupMessagesByRelativeDate(updatedMessages);

      setSelectedChatRoomData((prevState) => ({
        ...prevState,
        data: groupedMessages,
      }));

      // If you also store messages separately in `selectedChatRoomDataOnly`
      setSelectedChatRoomDataOnly((prevData) =>
        replaceMessageById(prevData, updatedPusherMessage)
      );

      setChatUserList(prevState => {
        const updatedList = prevState.map(chatUser => {
          if (chatUser?.chat_room_id === selectedChatRoom && chatUser?.last_message_id === updatedPusherMessage?.id) {
            return {
              ...chatUser,
              last_message_sender: updatedPusherMessage?.sender_detail?.full_name,
              last_message: updatedPusherMessage?.message,
              message_time: updatedPusherMessage?.message_time,
            };
          }
          return chatUser;
        });

        return updatedList;
      });
    }
    else if (selectedChatRoom != pusherMessage?.chat_room_id && isChatRoomExist) {

      const newMessage = pusherMessage;

      setChatUserList(prevState => {
        const updatedList = prevState.map(chatUser => {
          if (chatUser?.chat_room_id === pusherMessage?.chat_room_id && chatUser?.last_message_id === updatedPusherMessage?.id) {
            return {
              ...chatUser,
              last_message_sender: updatedPusherMessage?.sender_detail?.full_name,

              last_message: updatedPusherMessage?.message,
              message_time: updatedPusherMessage?.message_time,
            };
          }
          return chatUser;
        });

        return updatedList;
      });

    }
  }

  const manageRemoveChat = () => {
    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id === deletePusherMessage?.chat_room_id)
    // console.log("isChatRoomExist::", isChatRoomExist)
    if (selectedChatRoom === deletePusherMessage?.chat_room_id && isChatRoomExist) {

      const filteredMessages = Object.values(selectedChatRoomData?.data)
        .flatMap(group => Object.values(group).flat())
        .filter((msg) => msg?.id !== deletePusherMessage?.id);

      // Regroup the messages by relative date after filtering
      const groupedMessages = groupMessagesByRelativeDate(filteredMessages);

      // Update the state with the filtered messages
      setSelectedChatRoomData((prevState) => ({
        ...prevState,
        data: groupedMessages,
      }));

      // Update the separate list if needed
      setSelectedChatRoomDataOnly((prevData) =>
        prevData.filter((msg) => msg?.id !== deletePusherMessage?.id)
      );

      // Check if the deleted message was the last message
      const isLastMessage = isChatRoomExist?.last_message_id === deletePusherMessage?.id;
      if (isLastMessage) {
        const lastMessage = filteredMessages.length > 0 ? filteredMessages[filteredMessages.length - 1] : null;

        // Update the chat user list with the previous message as the last message
        setChatUserList((prevChatUserList) =>
          prevChatUserList.map((chatUser) => {
            if (chatUser?.chat_room_id === deletePusherMessage?.chat_room_id) {
              return {
                ...chatUser,
                last_message: lastMessage?.message || '',
                last_message_id: lastMessage?.id || '',
                last_message_sender: lastMessage?.sender_detail?.full_name || '',
                message_time: lastMessage?.message_time || '',
              };
            }
            return chatUser;
          })
        );
      }
      ManageChatRoomData()

    }
    else if (selectedChatRoom != deletePusherMessage?.chat_room_id && isChatRoomExist) {
      const newMessage = deletePusherMessage;
    }
  }

  const manageReactionData = () => {

    let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id === reactionPusherMessage?.chat_room_id)

    if (selectedChatRoom === reactionPusherMessage?.chat_room_id && isChatRoomExist) {
      // Update the selectedChatRoomData
      setSelectedChatRoomData((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };

        // Iterate over the date groups
        for (let date in updatedData.data) {
          // Iterate over the time groups within the current date
          for (let time in updatedData.data[date]) {
            // Iterate over each message in the current time group
            updatedData.data[date][time] = updatedData.data[date][time].map((message) => {
              if (message.id === reactionPusherMessage?.id) {
                // Update the message with the new reaction data
                return {
                  ...message,
                  total_reaction: reactionPusherMessage?.total_reaction,
                  user_self_reaction: reactionPusherMessage?.user_self_reaction,
                  updated_time: reactionPusherMessage?.updated_time || message.updated_time,
                };
              }
              return message;
            });
          }
        }

        return updatedData;
      });
    }
  }

  const handleLeaveConversation = () => {
    handleCloseForEdit()
    setOpenConversationModel(false)
    setOpenLeaveConversationAlertModel(true)
  }

  const handlecloseLeaveConversationModel = () => {
    setOpenLeaveConversationAlertModel(false)
  }

  const handleDeleteChatModel = () => {
    handleCloseForEdit()
    setOpenConversationModel(false)
    setOpenDeleteChatAlertModel(true)
  }

  const handlecloseDeleteChatModel = () => {
    setOpenDeleteChatAlertModel(false)
    setIsEditableChat(false)
    setCurrentChatID()
    setEditChatMsg("")
  }

  const handleCloseAddGroupNameModel = () => {
    setOpenGroupNameModel(false)
    setGroupName("")
  }

  const handleUserClick = (user) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.some((u) => u.id === user.id)) {
        return prevSelected.filter((u) => u.id !== user.id);
      } else {
        return [...prevSelected, user];
      }
    });
  };

  function linkifyMessage(message) {
    // Extended regular expression to match URLs including those that start with www.
    const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;

    return message.replace(urlRegex, (url, index) => {
      // Add 'http://' if the URL starts with 'www.'
      const href = url.startsWith('www.') ? `http://${url}` : url;

      return `<a href="${href}" class="url-link" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  }

  const filterUsers = () => {
    let searchTerm = searchUsers;
    let users = getFullUserList;
    if (searchTerm?.length < 2) return users;
    return users?.filter(user =>
      user?.full_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  };

  const ManageChatRoomDataForTargetComment = async () => {
    setCurrentChatID()
    setIsEditableChat(false)

    try {
      setIsChatRoomLoading(true);
      let url = `user/message-list/${targetChatRoomId || chatRoomId || selectedChatRoom}`
      if (targetChatId && targetChatRoomId) {
        url += `?message_id=${targetChatId}`
      }

      const response = await api.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const chatRoomData = {
          data: groupMessagesByRelativeDate(response?.response?.data?.slice()),
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details
        }
        const chatRoomDataOnly = {
          data: response?.response?.data,
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details
        }
        setLoading(false);
        setHasMoreMessagesDown(response?.response?.links?.next !== null);
        setIsChatRoomLoading(false);

        setSelectedChatRoomData(chatRoomData)
        setSelectedChatRoomDataOnly(chatRoomDataOnly?.data)
        setHasMoreMessages(response?.response?.links?.next !== null);
        setCurrentChatID()
        setIsEditableChat(false)
        fetchUnreadMsgCount()

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    } finally {
      setIsChatRoomLoading(false);
    }
  }
  const ManageChatRoomData = async () => {
    setCurrentChatID()
    setIsEditableChat(false)

    try {
      setIsChatRoomLoading(true);
      const response = await api.get(
        `user/message-list/${selectedChatRoom}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const chatRoomData = {
          data: groupMessagesByRelativeDate(response?.response?.data?.slice()?.reverse()),
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details
        }
        const chatRoomDataOnly = {
          data: response?.response?.data,
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details
        }

        setLoading(false);
        setIsChatRoomLoading(false);

        setSelectedChatRoomData(chatRoomData)
        setSelectedChatRoomDataOnly(chatRoomDataOnly?.data)
        setHasMoreMessages(response?.response?.links?.next !== null);
        setCurrentChatID()
        setIsEditableChat(false)
        fetchUnreadMsgCount()

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    } finally {
      setIsChatRoomLoading(false);
    }
  }

  const searchChatData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/message-list/${selectedChatRoom}?search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const newMessageIds = response?.response?.data?.map(chat => chat?.id);
        const existingMessageIds = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat()).map(chat => chat.id);

        const missingIds = newMessageIds.filter(id => !existingMessageIds.includes(id));

        const combinedIds = [...existingChatIds, ...existingMessageIds];

        setExistingChatIds(combinedIds)
        setMissingChatIds(missingIds)

        const chatRoomData = {
          data: groupMessagesByRelativeDate(response?.response?.data),
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details
        }
        const chatRoomDataOnly = {
          data: response?.response?.data,
          links: response?.response?.links,
          meta: response?.response?.meta,
          room_details: response?.response?.room_details
        }
        if (missingIds?.length < 1) {
          const results = [];
          Object.keys(chatRoomData.data).forEach(date => {
            Object.keys(chatRoomData.data[date]).forEach(time => {
              chatRoomData.data[date][time].forEach(chat => {
                if (chat.message.toLowerCase().includes(searchQuery.toLowerCase())) {
                  results.push(chat.id);
                }
              });
            });
          });
          setSearchResults(results);
          const lastIndex = results?.length - 1;
          setHasMoreChatMessages(response?.response?.links?.next !== null);

          // Navigate to the last result if it exists
          if (lastIndex >= 0) {
            setCurrentHighlightIndex(lastIndex);
            setNavigatedChatId(results[lastIndex]);
          } else {
            // If no results, reset navigation
            setCurrentHighlightIndex(0);
            setNavigatedChatId(null);
          }
        }
        setSearchChatMessageData(chatRoomData)


        // Toast.success(response?.message);
        setLoading(false);
        setHasMoreChatMessages(response?.response?.links?.next !== null)

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const checkIfAllChatIdsAvailable = (chatIds, chatRoomData) => {
    const allChatIds = Object.values(chatRoomData)
      .flat(Infinity)
      .map((chat) => chat.id);

    return chatIds.every((id) => allChatIds.includes(id));
  };

  const handleError = (err) => {
    console.log("Error in fetch news api:", err);
    if (err?.response?.status === 401) {
      logoutHelper(dispatch, navigate, userToken);
    } else if (err?.response?.status === 422) {
      const errorData = Object.values(err?.response?.data?.errors).forEach((value) => Toast.error(value));
    } else if (err?.response?.status === 429) {
      Toast.error("Too many attempts. Wait for 2 minutes.");
    } else {
      Toast.error("An error occurred. Please try again.");
    }
  };

  const toggleReaction = (id, reaction) => {
    handleReactionAPI(id, reaction)
  }

  const createConversation = async () => {
    if (selectedUsers?.length < 2 && isEditConversation === false) {

      try {
        setLoading(true);
        const response = await api.post(
          `user/chat-create/${selectedUsers[0]?.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success === true) {
          const isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id === response?.response?.chat_room_id)
          setLoading(false);
          if (isChatRoomExist) {
            setSelectedChatRoom(response?.response?.chat_room_id)
          } else {
            setChatUserList((prevList) => [response?.response, ...prevList]);
            setSelectedChatRoom(response?.response?.chat_room_id)
          }
          handleCloseConversationModel()
          handleCloseForStartConversation()
          setSelectedUsers([])
          setCurrentChatID()
          setIsEditableChat(false)

        } else if (response?.success === false) {
          setLoading(false);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("err`or in fetch news api::", err);
        if (err?.response?.status === 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status === 422) {
          setLoading(false);

          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status === 429) {
          Toast.error("Too many attemps wait for 2 min.")
        }
        setLoading(false);
      }
    } else if (selectedUsers?.length > 1 && isEditConversation === false) {

      const viewSelectedUsers = selectedUsers?.map((user) => user?.id)
      const users = viewSelectedUsers?.join(",")

      let data = {
        name: groupName,
        chat_room_id: selectedChatRoom,
        users: users
      }
      if (isEditConversation === false) {
        try {
          setLoading(true);
          const response = await api.post(
            `user/create-group`,
            data,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            Toast.success(response?.message);
            const isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id === response?.response?.chat_room_id)
            setLoading(false);
            setGroupName("")
            if (isChatRoomExist) {
              setSelectedChatRoom(response?.response?.chat_room_id)
            } else {
              setChatUserList((prevList) => [response?.response, ...prevList]);
              setSelectedChatRoom(response?.response?.chat_room_id)
            }

            setSelectedGroupUsers([])
            handleCloseConversationModel()
            handleCloseAddGroupNameModel()
            handleClickForStartConversation()
            setSelectedUsers([])
            setCurrentChatID()
            setIsEditableChat(false)

          } else if (response?.success === false) {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("err`or in fetch news api::", err);
          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);

            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
          setLoading(false);
        }
      }

    }
    else if (isEditConversation === true) {
      let data = {
        name: groupName,
        chat_room_id: selectedChatRoom,
        users: selectedGroupUsers?.map((user) => {
          return {
            label: user?.label,
            value: user?.value,
            is_admin: user?.is_admin === true ? 1 : 0
          }
        })
      }
      try {
        setLoading(true);
        const response = await api.post(
          `user/update-group`,
          data,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success === true) {
          // Toast.success(response?.message);
          setLoading(false);
          setGroupName()
          setSelectedGroupUsers([])
          handleCloseConversationModel()
          fetchAllChatRoomList()
          ManageChatRoomData()
          setCurrentChatID()
          setIsEditableChat(false)

        } else if (response?.success === false) {
          setLoading(false);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("err`or in fetch news api::", err);
        if (err?.response?.status === 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status === 422) {
          setLoading(false);

          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status === 429) {
          Toast.error("Too many attemps wait for 2 min.")
        }
        setLoading(false);
      }
    }
  }

  const fetchConversationDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/edit-group/${selectedChatRoom}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        // Toast.success(response?.message);
        setConversationType("group")
        setGroupName(response?.response?.name)
        const users = response?.response?.group_user?.map((user) => {
          return {
            label: user?.label,
            value: user?.value,
            is_admin: user?.is_admin === 1 ? true : false
          }
        })
        setSelectedGroupUsers(users)
        setLoading(false);
        handleCloseForEdit()
        setOpenConversationModel(true)

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const sendMessage = async () => {
    if (targetChatRoomId || targetChatId) {
      resetUrl();
      ManageChatRoomData();
    }
    if (typedMessage === "") {
      return Toast.error("Please enter message!")
    }

    const tempId = `temp_${Math.random().toString(36).substring(2, 15)}`;
    const tempMessageTime = Math.floor(Date.now() / 1000);
    let tempMessage;
    if (isReplyQuote === true) {
      tempMessage = {
        id: tempId,
        sender_id: loggedUser?.id, // Assuming `user` is the logged-in user info
        receiver_id: null,
        message: typedMessage,
        chat_room_id: selectedChatRoomData?.room_details?.chat_room_id,
        message_time: tempMessageTime,
        sender_detail: {
          id: loggedUser?.id,
          full_name: loggedUser?.name || "You", // Assuming `user` has `full_name`
          name: loggedUser?.name,
          last_name: loggedUser?.last_name,
          username: loggedUser?.username,
          thumbnail: loggedUser?.thumbnail || "" // Assuming `user` has `thumbnail`
        },
        quote_message: {
          id: 1,
          message: typedMessage,
          message_id: replyQuoteID,
          sender_id: loggedUser?.id,
          reply_message_id: tempId,
          created_at: tempMessageTime,
          updated_at: tempMessageTime,
          sender_detail: {
            id: loggedUser?.id,
            full_name: loggedUser?.name || "You", // Assuming `user` has `full_name`
            name: loggedUser?.name,
            last_name: loggedUser?.last_name,
            username: loggedUser?.username,
            thumbnail: loggedUser?.thumbnail || "" // Assuming `user` has `thumbnail`
          },
        },
        receiver_detail: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        isSent: false, // Mark as unsent initially
      };
    } else {
      tempMessage = {
        id: tempId,
        sender_id: loggedUser?.id, // Assuming `user` is the logged-in user info
        receiver_id: null,
        message: typedMessage,
        chat_room_id: selectedChatRoomData?.room_details?.chat_room_id,
        message_time: tempMessageTime,
        sender_detail: {
          id: loggedUser?.id,
          full_name: loggedUser?.name || "You", // Assuming `user` has `full_name`
          name: loggedUser?.name,
          last_name: loggedUser?.last_name,
          username: loggedUser?.username,
          thumbnail: loggedUser?.thumbnail || "" // Assuming `user` has `thumbnail`
        },

        receiver_detail: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        isSent: false, // Mark as unsent initially
      };
    }

    let data = {
      chat_room_id: selectedChatRoomData?.room_details?.chat_room_id,
      receiver_id: selectedChatRoomData?.room_details?.user_id,
      message: typedMessage,
      type: selectedChatRoomData?.room_details?.type,
      temp_id: tempId,
      reply_id: replyQuoteID
    }
    handleCloseForReplyQuote()
    // console.log("tempMessage:::", tempMessage)

    // Flatten existing grouped messages
    const flattenedMessages = Object.values(selectedChatRoomData?.data)
      .flatMap(group => Object.values(group).flat());

    // Add the new message to the array
    const updatedMessages = [...flattenedMessages, tempMessage];

    // Re-group messages
    const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

    // Update your state or data structure here
    setSelectedChatRoomData({
      ...selectedChatRoomData,
      data: regroupedMessages
    });
    setSelectedChatRoomDataOnly((prevData) => [...prevData, tempMessage]);
    setTypedMessage("");
    if (isTyping) {
      setIsTyping(false)
    }
    try {
      setIsLoadingMorePressed(false)
      const response = await api.post(
        `user/send-message`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response?.success === true) {
        // Toast.success(response?.message);
        setLoading(false);

        const actualMessage = {
          ...response?.response,
          isSent: true, // Mark as sent
        };

        const updatedMessagesWithActual = Object.values(selectedChatRoomData?.data).flat().map((msg) =>
          msg?.id === tempId ? actualMessage : msg
        );
        // console.log("updatedMessagesWithActual::", updatedMessagesWithActual)

        const flattenedMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat());

        // Add the new message to the array
        const updatedMessages = [...flattenedMessages, actualMessage];

        // Re-group messages
        const regroupedMessages = groupMessagesByRelativeDate(updatedMessages);

        // Update your state or data structure here
        setSelectedChatRoomData({
          ...selectedChatRoomData,
          data: regroupedMessages
        });


        setTypedMessage("")
        setHasStartedTyping(false);

        setChatUserList(prevState => {
          const updatedList = prevState.map(chatUser => {
            if (chatUser?.chat_room_id === selectedChatRoom) {
              return {
                ...chatUser,
                last_message_sender: loggedUser?.name,
                last_message: typedMessage,
                message_time: actualMessage?.message_time,
                last_message_id: actualMessage?.id
              };
            }
            return chatUser;
          });

          // Move the updated chat room to the top
          const chatUserIndex = updatedList.findIndex(chatUser => chatUser?.chat_room_id === selectedChatRoom);
          if (chatUserIndex > -1) {
            const [updatedChatUser] = updatedList.splice(chatUserIndex, 1);
            updatedList.unshift(updatedChatUser);
          }

          return updatedList;
        });

        setIsSelectedEdit(false)
        setCurrentChatID()
        setIsEditableChat(false)


      } else if (response?.success === false) {
        setLoading(false);
        if (response?.response?.member_exist === false) {
          Toast.error("you are not member of this conversation!")
          setSelectedChatRoom(undefined)
          setSelectedChatRoomData()
          setSelectedChatRoomDataOnly([])
          fetchAllChatRoomList()
        } else {
          Toast.error(response?.message);

        }
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const updateMessage = async () => {
    if (targetChatRoomId || targetChatId) {
      resetUrl();
      ManageChatRoomData();
    }
    if (typedMessage === "") {
      return Toast.error("Please enter message!")
    }
    let data = {
      message: typedMessage,
    }
    // console.log("tempMessage:::", tempMessage)
    const tempId = currentChatID; // ID of the message being updated

    // Find the message in the current chat data and update its content with the `typedMessage`
    const tempUpdatedMessage = Object.values(selectedChatRoomData?.data)
      .flatMap(group => Object.values(group).flat())
      .map((msg) =>
        msg.id === tempId
          ? { ...msg, message: typedMessage, isUpdating: true } // Mark as updating
          : msg
      );

    const groupedMessagesTemp = groupMessagesByRelativeDate(tempUpdatedMessage);

    setSelectedChatRoomData((prevState) => ({
      ...prevState,
      data: groupedMessagesTemp,
    }));

    setTypedMessage("");

    try {
      setIsLoadingMorePressed(true)

      const response = await api.post(
        `user/update-message/${currentChatID}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response?.success === true) {
        setLoading(false);
        const actualMessage = {
          ...response?.response,
          // isSent: true, // Mark as sent
        };

        setTypedMessage("")
        setIsEditableChat(false)
        setEditChatMsg("")
        // Reset the start typing flag

        setHasStartedTyping(false);
        const updatedMessages = replaceMessageById(Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat()), actualMessage);

        const groupedMessages = groupMessagesByRelativeDate(updatedMessages);

        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: groupedMessages,
        }));

        // If you also store messages separately in `selectedChatRoomDataOnly`
        setSelectedChatRoomDataOnly((prevData) =>
          replaceMessageById(prevData, actualMessage)
        );

        setChatUserList(prevState => {
          const updatedList = prevState.map(chatUser => {
            if (chatUser?.chat_room_id === actualMessage?.chat_room_id && chatUser?.last_message_id === currentChatID) {
              return {
                ...chatUser,
                last_message_sender: loggedUser?.name,
                last_message: actualMessage?.message,
                message_time: actualMessage?.message_time,
              };
            }
            return chatUser;
          });

          return updatedList;
        });

        setIsSelectedEdit(false)
        setCurrentChatID()
        setIsEditableChat(false)

      } else if (response?.success === false) {
        setLoading(false);
        if (response?.response?.member_exist === false) {
          Toast.error("you are not member of this conversation!")
          setSelectedChatRoom(undefined)
          setSelectedChatRoomData()
          setSelectedChatRoomDataOnly([])
          fetchAllChatRoomList()
        } else {
          Toast.error(response?.message);

        }
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const fetchAllUser = async () => {
    try {
      const response = await api.get(`users-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        let users = response?.response?.filter((user) => user?.id !== loggedUser?.id)?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }))

        let fullUser = response?.response?.filter((user) => user?.id !== loggedUser?.id)

        let groupUsers = response?.response?.filter((user) => user?.id !== loggedUser?.id).map((user) => {
          return {
            value: user?.id,
            label: user?.name,
            is_admin: false
          }
        })

        setGetAllUsers(users);
        setGetUserList(fullUser)
        setGetFullUserList(fullUser)
        setGetAllGroupUsers(groupUsers)
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const fetchAllChatRoomList = async () => {
    try {
      // setLoading(true)
      setIsUserlistLoading(true);
      const response = await api.post(`user/chat-user-list`, {}, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success === true) {
        setLoading(false)
        setChatUserList(response?.response?.data)
      }
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    } finally {
      setIsUserlistLoading(false);
    }
  }

  const leaveConversationApi = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/leave-group/${selectedChatRoom}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {

        // Toast.success(response?.message);
        handleCloseConversationModel()
        setOpenLeaveConversationAlertModel(false)
        setSelectedChatRoom(undefined)
        setSelectedChatRoomData()
        fetchAllChatRoomList()

        setLoading(false);

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const deleteMessageAPI = async () => {
    try {
      setLoading(true);
      const response = await api.delete(
        `user/delete-message/${currentChatID}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setIsLoadingMorePressed(true)

        // Toast.success(response?.message);
        let isChatRoomExist = chatUserList?.find((chatuserlist) => chatuserlist?.chat_room_id === selectedChatRoom)

        const filteredMessages = Object.values(selectedChatRoomData?.data)
          .flatMap(group => Object.values(group).flat())
          .filter((msg) => msg.id !== currentChatID);

        // Regroup the messages by relative date after filtering
        const groupedMessages = groupMessagesByRelativeDate(filteredMessages);

        // Update the state with the filtered messages
        setSelectedChatRoomData((prevState) => ({
          ...prevState,
          data: groupedMessages,
        }));

        // Update the separate list if needed
        setSelectedChatRoomDataOnly((prevData) =>
          prevData.filter((msg) => msg.id !== currentChatID)
        );

        // Check if the deleted message was the last message
        const isLastMessage = isChatRoomExist?.last_message_id === currentChatID;
        if (isLastMessage) {
          const lastMessage = filteredMessages.length > 0 ? filteredMessages[filteredMessages.length - 1] : null;

          // Update the chat user list with the previous message as the last message
          setChatUserList((prevChatUserList) =>
            prevChatUserList.map((chatUser) => {
              if (chatUser?.chat_room_id === selectedChatRoom) {
                return {
                  ...chatUser,
                  last_message: lastMessage?.message || '',
                  last_message_id: lastMessage?.id || '',
                  last_message_sender: lastMessage?.sender_detail?.full_name || '',
                  message_time: lastMessage?.message_time || '',
                };
              }
              return chatUser;
            })
          );
        }
        ManageChatRoomData()

        handlecloseDeleteChatModel()
        handleCloseForChatEdit()
        setIsSelectedEdit(false)
        setLoading(false);
        setCurrentChatID()
        setIsEditableChat(false)

      } else if (response?.success === false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);

        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const handleReactionAPI = async (id, reaction) => {
    let data = {
      reaction: reaction
    };

    try {
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/message-reaction/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        const updatedMessage = response?.response;

        setSelectedChatRoomData((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };

          // Iterate over the date groups
          for (let date in updatedData.data) {
            // Iterate over the time groups within the current date
            for (let time in updatedData.data[date]) {
              // Iterate over each message in the current time group
              updatedData.data[date][time] = updatedData.data[date][time].map((message) => {
                if (message.id === id) {
                  // Update the message with the new reaction data
                  return {
                    ...message,
                    total_reaction: updatedMessage?.total_reaction,
                    user_self_reaction: updatedMessage?.user_self_reaction,
                    updated_time: updatedMessage?.updated_time || message.updated_time,
                  };
                }
                return message;
              });
            }
          }

          return updatedData;
        });

        setIsLoadingMorePressed(true);
        setLoading(false);
        handlePopoverClose();
        setCurrentChatID(null);
        setIsEditableChat(false);

      } else {
        handlePopoverClose();
        setLoading(false);
        Toast.error(response?.response);

      }
    } catch (err) {
      console.log("error in handleReactionAPI::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      } else if (err?.response?.status === 422) {
        handlePopoverClose();
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  };


  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps3(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === '') {
      setSearchResults([]);
      setNavigatedChatId(null); // Reset navigated chat ID when the search query is cleared
    } else {
      const results = [];
      Object.keys(selectedChatRoomData.data).forEach(date => {
        selectedChatRoomData.data[date].forEach(chat => {
          if (chat.message.toLowerCase().includes(e.target.value.toLowerCase())) {
            results.push(chat.id);
          }
        });
      });
      setSearchResults(results);
      // Set the index to the last element in the results array
      const lastIndex = results.length - 1;

      // Navigate to the last result if it exists
      if (lastIndex >= 0) {
        setCurrentHighlightIndex(lastIndex);
        setNavigatedChatId(results[lastIndex]);
      } else {
        // If no results, reset navigation
        setCurrentHighlightIndex(0);
        setNavigatedChatId(null);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Shift + Enter: Go to the previous highlighted message

        setCurrentHighlightIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % searchResults.length;
          setNavigatedChatId(searchResults[nextIndex]); // Set the new navigated chat ID
          return nextIndex;
        });
      } else {
        // Enter: Go to the next highlighted message

        if (currentHighlightIndex === 0 && hasMoreSearchChatMessages) {
          loadMoreMessageDataForSearch()
        }

        setCurrentHighlightIndex((prevIndex) => {
          const prevHighlightIndex = (prevIndex - 1 + searchResults.length) % searchResults.length;
          setNavigatedChatId(searchResults[prevHighlightIndex]); // Set the new navigated chat ID
          return prevHighlightIndex;
        });
      }
    }
  };

  const isMessageHighlighted = (chatId) => {
    return searchResults.includes(chatId);
  };

  const isMessageNavigated = (chatId) => {
    return navigatedChatId === chatId;
  };

  const resetUrl = () => {
    setTargetChatId(null);
    setTargetChatRoomId(null);
    if (searchParams.has('chat_room')) {
      searchParams.delete('chat_room')
    }
    if (searchParams.has('chat_id')) {
      searchParams.delete('chat_id')
    }
    const newUrl = `${window.location.pathname}`;
    window.history.replaceState(null, '', newUrl);
  }

  // For change in chat search params
  useEffect(() => {

    if (chatId && chatRoomId) {
      if (targetChatId !== chatId || targetChatRoomId !== chatRoomId) {
        setTargetChatId(chatId);
        setTargetChatRoomId(chatRoomId);
        setSelectedChatRoom(chatRoomId);
      }
    }
  }, [location]);

  useEffect(() => {
    setLoading(true)
    fetchAllUser()
    fetchAllChatRoomList()
  }, [])

  useEffect(() => {
    if ((!selectedChatRoom || selectedChatRoom === undefined) && chatUserList.length > 0) {
      setSelectedChatRoom(chatUserList[0]?.chat_room_id);
    }
  }, [chatUserList]);

  useEffect(() => {
    console.log("presence current typing data", currentUserTypingData)
    setUserTypingMessageData(formatTypingMessageOutput());
  }, [currentUserTypingData])

  useEffect(() => {
    console.log("presence current typing message", userTypingMessageData)
  }, [userTypingMessageData]);

  useEffect(() => {

    if (selectedChatRoom !== undefined) {
      setCurrentUserTypingData({});

      setTypedMessage("")

      setIsLoadingMorePressed(false)
      if (targetChatId && targetChatRoomId) {
        ManageChatRoomDataForTargetComment();
      } else {
        ManageChatRoomData()
      }
      setChatUserList(prevState => prevState.map(chatUser => {
        if (chatUser?.chat_room_id === selectedChatRoom) {
          return {
            ...chatUser,
            unread_count: 0
          };
        }
        return chatUser;
      }));
    }
  }, [selectedChatRoom]);

  useEffect(() => {
    console.log("selected chat room id before typing initaiat", selectedChatRoom);
    if (selectedChatRoom) {
      const decPusherAppCluster = decryptData(pusherData?.app_cluster)
      const decPusherApKey = decryptData(pusherData?.app_key)
      const pusherInstanceForTyping = new Pusher(decPusherApKey, {
        cluster: decPusherAppCluster,
        auth: {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Accept': 'application/json',
          }
        },
        authEndpoint: `${apiURL}/broadcasting/auth`,
        channelAuthorization: {
          endpoint: `${apiURL}/broadcasting/auth`,
          transport: "ajax",
          params: {
            is_typing: isTyping ?? false
          },
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      });



      const presenceTypingChannel = pusherInstanceForTyping?.subscribe(`presence-typing-${tenantData?.tenant_id}-${selectedChatRoomID || selectedChatRoom}`)
      console.log("presence ::", presenceTypingChannel);

      presenceTypingChannel.bind("pusher:subscription_succeeded", (members) => {
        console.log("presence succeeded::", members);
        const userData = members?.members;

        let typingUsers = {};

        // Iterate over the users and store the ones who are typing
        Object.values(userData).forEach((user) => {
          if (user?.is_typing === "true" && !currentUserTypingData[user?.id]) {
            typingUsers = {
              ...typingUsers,
              [user?.id]: user,  // Store the user data in the temporary object
            };
          }
        });

        // After iteration, set the state if there are new typing users
        if (Object.keys(typingUsers).length > 0) {
          setCurrentUserTypingData((prev) => ({
            ...prev,
            ...typingUsers,  // Append all new typing users to the state
          }));
        }
      }
      );
      presenceTypingChannel.bind("pusher:member_added", (member) => {
        console.log("presence member added::", member)
        let typingUsers = {};
        if (member?.info?.is_typing === 'true') {
          typingUsers = { [member?.id]: member?.info }
        }
        if (Object.keys(typingUsers).length > 0) {
          setCurrentUserTypingData((prev) => ({
            ...prev,
            ...typingUsers,
          }));
        }
      }

      );
      presenceTypingChannel.bind("UserTyping", (member) => {
        console.log("presence userTyping added::", member)
        let typingUsers = {};
        if (member?.info?.is_typing === 'true') {
          typingUsers = { [member?.id]: member?.info }
        }
        if (Object.keys(typingUsers).length > 0) {
          setCurrentUserTypingData((prev) => ({
            ...prev,
            ...typingUsers,
          }));
        }
      }

      );
      presenceTypingChannel.bind("pusher:member_removed", (member) => {
        console.log("presence member removed::", member);
        setCurrentUserTypingData((prev) => {
          const updatedState = { ...prev };

          // Check if the member ID exists in the current state
          if (member?.id && updatedState[member?.id]) {
            // Remove the member from the state
            delete updatedState[member.id];
          }

          return updatedState; // Return the updated state
        });
      });
      presenceTypingChannel.bind("UserStoppedTyping", (member) => {
        console.log("presence userstopped type removed::", member);
        setCurrentUserTypingData((prev) => {
          const updatedState = { ...prev };

          // Check if the member ID exists in the current state
          if (member?.id && updatedState[member?.id]) {
            // Remove the member from the state
            delete updatedState[member.id];
          }

          return updatedState; // Return the updated state
        });
      });

      return () => {
        console.log('presence inide clenup useefect', pusherInstanceForTyping);
        console.log('presence inide clenup useefect selected chat room id is', selectedChatRoomID);
        pusherInstanceForTyping?.unsubscribe(`presence-typing-${tenantData?.tenant_id}-${selectedChatRoomID}`)
        pusherInstanceForTyping?.disconnect();
      };

    }
  }, [selectedChatRoom]);

  const typingTimeoutNewRef = useRef(null);

  useEffect(() => {
    if (selectedChatRoom !== undefined) {
      if (isTyping) {
        startTypingData();
      } else {
        stopTypingData();
      }
    }
  }, [isTyping]);


  const handleTyping = () => {
    clearTimeout(typingTimeoutNewRef.current);
    if (!isTyping) {
      setIsTyping(true);
    }

    typingTimeoutNewRef.current = setTimeout(() => {
      if (isTyping) {
        setIsTyping(false);  // Set to false after a delay (e.g., 5 seconds of inactivity)
      }
    }, 7000);
  };

  useEffect(() => {
    if (isLoadingMorePressed === false) {

      if (chatContainerRef.current) {
        if (!targetChatId) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        } else {
          if (isTargetMessageSeen) {
            // chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
          }
        }
      }
    }

    if (!isTargetMessageSeen && targetChatId) {
      const targetMessageElement = document.getElementById(`chat-${targetChatId}`);

      if (targetMessageElement) {
        targetMessageElement.style.transition = 'background-color 4s ease';
        targetMessageElement.style.backgroundColor = '#660c6029';
        targetMessageElement.style.borderRadius = '10px';

        targetMessageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => {
          targetMessageElement.style.backgroundColor = ''; // Reset to default
          targetMessageElement.style.borderRadius = ''; // Reset to default
        }, 2000);
        setIsTargetMessageSeen(true);
      }
    }

  }, [selectedChatRoomData]);

  useEffect(() => {
    if (!targetChatId) {
      const handleScroll = () => {
        if (chatContainerRef?.current) {
          const { scrollTop, scrollHeight, clientHeight } = chatContainerRef?.current;
          const scrollPosition = scrollTop / (scrollHeight - clientHeight);

          // Load more messages if scrolled within the top 30%
          if (scrollPosition <= 0.3 && !isLoadingMorePressed) {
            loadMoreMessages();
          }
        }
      };

      const chatContainer = chatContainerRef?.current;
      chatContainer?.addEventListener('scroll', handleScroll);

      return () => {
        chatContainer?.removeEventListener('scroll', handleScroll);
      };
    }

  }, [loadMoreMessages, isLoadingMorePressed]);

  useEffect(() => {
    if (pusherMessage !== undefined) {
      console.log('New Pusher message:', pusherMessage);
      manageChatData()
    }
  }, [pusherMessage])

  useEffect(() => {
    if (updatedPusherMessage !== undefined) {
      console.log('New Updated Pusher message:', updatedPusherMessage);
      manageUpdatedChatData()
    }
  }, [updatedPusherMessage])

  useEffect(() => {
    if (deletePusherMessage !== undefined) {
      console.log('New Updated Pusher message:', updatedPusherMessage);
      manageRemoveChat()
    }
  }, [deletePusherMessage])

  useEffect(() => {
    if (reactionPusherMessage !== undefined) {
      console.log('New Updated reaction Pusher message:', reactionPusherMessage);
      manageReactionData()
    }
  }, [reactionPusherMessage])

  useEffect(() => {
    if (isSearchTyping === true && searchUsers !== "") {
      const userData = filterUsers()
      setGetUserList(userData)
    } else if (isSearchTyping === true && searchUsers === "") {
      setGetUserList(getFullUserList)
    }
  }, [searchUsers])

  useEffect(() => {
    if (searchResults.length > 0 && chatContainerRef.current) {
      const highlightedElement = document.getElementById(`chat-${searchResults[currentHighlightIndex]}`);
      if (highlightedElement) {
        highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setNavigatedChatId(searchResults[currentHighlightIndex]); // Set the current chat as navigated
      }
    }
  }, [searchResults, currentHighlightIndex]);

  useEffect(() => {
    if (searchResults.length > 0 && chatContainerRef.current) {
      const highlightedElement = document.getElementById(`chat-${searchResults[currentHighlightIndex]}`);
      if (highlightedElement) {
        highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setNavigatedChatId(searchResults[currentHighlightIndex]); // Set the current chat as navigated
      }
    }
  }, [searchResults, currentHighlightIndex]);

  useEffect(() => {
    if (searchQuery.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        searchChatData()
      }, debounceTimeout);
    }
    else if (searchQuery === "") {
      setSearchResults([]);
      setNavigatedChatId(null);
    }
    else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  useEffect(() => {
    if (missingChatIds?.length > 0 && isChatSearching === true && searchQuery?.length >= 3) {
      loadMoreMessagesForSearch();
    } else if (missingChatIds?.length < 1 && isChatSearching === false && searchQuery?.length >= 3) {
      const results = [];

      Object.keys(selectedChatRoomData?.data)?.forEach(date => {
        Object.keys(selectedChatRoomData?.data[date])?.forEach(time => {
          selectedChatRoomData?.data[date][time]?.forEach(chat => {
            if (chat?.message?.toLowerCase().includes(searchQuery.toLowerCase())) {
              results.push(chat.id);
            }
          });
        });
      });

      setSearchResults(results);
      const lastIndex = results.length - 1;

      // Navigate to the last result if it exists
      if (lastIndex >= 0) {
        setCurrentHighlightIndex(lastIndex);
        setNavigatedChatId(results[lastIndex]);
      } else {
        // If no results, reset navigation
        setCurrentHighlightIndex(0);
        setNavigatedChatId(null);
      }
    }
  }, [missingChatIds, isChatSearching]);

  useEffect(() => {
    if (redirectionChatID !== undefined) {
      let targetMessage = document.getElementById(`chat-${redirectionChatID}`);

      if (!targetMessage) {
        setISRedirectionChatIDMissing(true)
      } else {
        handleScrollToQuotedMessage(redirectionChatID)
      }

    }
  }, [redirectionChatID])


  useEffect(() => {
    if (isRedirectionChatIDMissing === true && redirectionChatID !== undefined) {
      let targetMessage = document.getElementById(`chat-${redirectionChatID}`);
      loadMoreMessagesForRedirection()
    } else {
      handleScrollToQuotedMessage(redirectionChatID)
    }
  }, [isRedirectionChatIDMissing])

  useEffect(() => {
    if (anchorElForChat === null) {
      if (selectedChatRoom !== undefined) {
        fetchAllChatRoomList()
        if (targetChatId && targetChatRoomId) {
          ManageChatRoomDataForTargetComment();
        } else {
          ManageChatRoomData()
        }
      }
    }
  }, [anchorElForChat])

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          <div className="main-div-2-types">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >

                <Typography key="3" component="h3" color="text.primary">
                  Chat
                </Typography>
              </Breadcrumbs>

            </Stack>
            <Box>
              <Button className="btn start-conversation-btn"
                variant="outlined"
                aria-controls={openMenuForSatrtConversation ? "account-create-content" : undefined}
                aria-expanded={openMenuForSatrtConversation ? "true" : undefined}
                aria-describedby={idStartConversation}
                onClick={(e) => {
                  handleClickForStartConversation(e)
                }}>

                Start New Chat
                <FontAwesomeIcon icon={faPen} />
              </Button>
            </Box>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box className=" main-app-icon-div-4 d-flex flex-wrap">
            <Box className="users-con-chat-list-div">

              <Box className="">
                <List className="user-list">
                  {chatUserList?.map((chatUser) => (
                    <>
                      <ListItem alignItems="flex-start" className={`user-list-items-con cursor-pointer ${selectedChatRoom === chatUser?.chat_room_id && "selected-chat-room"}`} onClick={() => {
                        dispatch(headerAction.setPusherInitialized(false))
                        if (targetChatRoomId || targetChatId) {
                          resetUrl();
                        }
                        setSelectedChatRoom(chatUser?.chat_room_id)
                        localStorage.setItem("selected-chat-room-id", chatUser?.chat_room_id)
                      }} sx={{ padding: "15px" }}>
                        <ListItemAvatar className="user-list-avatar-div">
                          <Avatar alt={chatUser?.name} src={chatUser?.profile} className="user-list-avatar text-capitalize">{chatUser?.name?.charAt(0)}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Box className="d-flex justify-content-between">
                                <Typography className="chat-usr-name">{chatUser?.name}</Typography>

                                <Typography className="chat-msg-time ">
                                  {chatUser?.message_time !== "" && (
                                    formatMessageTime(chatUser?.message_time)
                                  )}
                                </Typography>
                              </Box>
                            </>
                          }
                          className="user-list-name chat-users-list"
                          secondary={
                            <>
                              <Box className="d-flex justify-content-between last-msg text-truncate">
                                {chatUser?.last_message_sender !== "" && (
                                  <Typography sx={{ fontSize: "12px" }} className="last-chat-msg text-truncate">{`${chatUser?.last_message_sender}:  ${chatUser?.last_message}`}</Typography>
                                )}
                                {chatUser?.last_message !== "" && chatUser?.unread_count > 0 && (
                                  <Typography className="chat-unread-count">
                                    {chatUser?.unread_count}
                                  </Typography>
                                )}
                              </Box>
                            </>
                          }
                        />
                      </ListItem>

                      <Divider component="li" />
                    </>
                  ))}

                </List>

                {isUserListloading && (
                  <UserListSkleton />
                )}
              </Box>
            </Box>
            <Box className="user-con-chat-div">
              {selectedChatRoomData !== undefined && !isChatRooomLoading && (
                <>
                  <Box sx={{ padding: "15px" }} className="d-flex justify-content-between align-items-center user-info-div" >
                    <Box className="d-flex gap-3 justify-content-center align-items-center">
                      <Avatar alt={selectedChatRoomData?.room_details?.name} src={selectedChatRoomData?.room_details?.profile} className="user-list-avatar text-capitalize">{selectedChatRoomData?.room_details?.name?.charAt(0)}</Avatar>
                      <Typography className="chat-room-name text-truncate">{selectedChatRoomData?.room_details?.name}</Typography>

                      {selectedChatRoomData?.room_details?.type === "private" && selectedChatRoomData?.room_details?.user_type === "user" && (
                        <Button className="btn view-profile-btn" variant="outlined" onClick={() => {
                          if (loggedUser?.user_type === "user") {
                            localStorage.setItem("last-redirect-page-user", `/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                            navigate(`/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                          }
                          else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
                            localStorage.setItem("last-redirect-page-admin-user", `/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`)
                            navigate(`/user-type/people/profile/view/${selectedChatRoomData?.room_details?.user_id}`);
                          }

                        }}>
                          View Profile
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      )}

                    </Box>
                    <Box className="d-flex gap-3 justify-content-end align-items-center">
                      <Box className="serach-box-user-con">
                        <InputGroup className="">
                          <Form.Control
                            placeholder=""
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            className="search-input"
                            value={searchQuery}
                            onChange={(e) => {
                              setIsChatSearchTyping(true)
                              setSearchQuery(e?.target?.value)
                            }}
                            onKeyDown={handleKeyPress}
                          />
                          <Button className="search-btn">
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </InputGroup>
                      </Box>
                      {selectedChatRoomData?.room_details?.type === "group" && (
                        <Box className="icon-bd-div cursor-pointer"
                          aria-controls={openMenuForEdit ? "account-create-content" : undefined}
                          aria-expanded={openMenuForEdit ? "true" : undefined}
                          aria-describedby={idForEdit}
                          onClick={(e) => {
                            handleClickForEdit(e)
                          }}>
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </Box>
                      )}

                    </Box>
                  </Box>

                  <Box sx={{ padding: "15px" }} className="user-chats-div-con" ref={chatContainerRef}>

                    {targetChatId ? (
                      <Box className="load-more-btn-div">
                        {hasMoreMessagesUp && (
                          <Button onClick={loadMoreMessagesUp} disabled={isLoadingMoreUp} className="load-more-btn text-capitalize" >
                            <FontAwesomeIcon icon={faAngleUp} />
                            {isLoadingMoreUp ? "Loading..." : "Load More"}
                          </Button>

                        )}
                      </Box>
                    ) : (<Box className="load-more-btn-div">
                      {hasMoreMessages && (
                        <Button onClick={loadMoreMessages} disabled={isLoadingMore} className="load-more-btn text-capitalize" >
                          <FontAwesomeIcon icon={faAngleUp} />
                          {isLoadingMore ? "Loading..." : "Load More"}
                        </Button>

                      )}
                    </Box>)
                    }
                    {Object?.keys(selectedChatRoomData?.data)?.length > 0 ? (
                      <Box className="full-chat-msg" sx={{ position: "relative", height: "100%" }}>
                        {Object?.keys(selectedChatRoomData?.data).map((date) => {
                          const messagesByTime = selectedChatRoomData?.data[date];
                          const timeGroups = selectedChatRoomData?.data[date];

                          return (
                            <div key={date} aria-controls={openForReaction ? "account-create-content" : undefined}
                              aria-expanded={openForReaction ? "true" : undefined}
                              aria-describedby={idForReaction} >
                              <Divider className="message-date text-center mb-2">
                                {date}
                              </Divider>
                              {Object.keys(timeGroups).map((time, index) => {
                                const messages = timeGroups[time];
                                // Step 1: Filter messages to have unique ids
                                const uniqueMessages = messages.reduce((acc, currentMessage) => {
                                  // Check if the current message id is already in the accumulator
                                  const isDuplicate = acc.find((message) => message.id === currentMessage.id);

                                  // If it's not a duplicate, add it to the accumulator
                                  if (!isDuplicate) {
                                    acc.push(currentMessage);
                                  }

                                  return acc;
                                }, []);
                                let lastUser = null;

                                const lastMessageSentByUser = messages?.find(chat => chat?.sender_id === loggedUser?.id);
                                return (
                                  <div>
                                    {uniqueMessages?.map((chat, index) => {
                                      const isSentByLoggedInUser = chat?.sender_id === loggedUser?.id;
                                      const highlighted = isMessageHighlighted(chat?.id);
                                      const navigated = isMessageNavigated(chat?.id);
                                      const showTimeAndName = lastUser !== chat?.sender_id;
                                      lastUser = chat?.sender_id;
                                      return (
                                        <>
                                          <Box
                                          >
                                            <div key={chat?.id}
                                              id={`chat-${chat?.id}`}
                                              className={`chat-message-con ${isSentByLoggedInUser ? 'right' : 'left'} `}

                                              onMouseLeave={handlePopoverCloseForDataOnly}
                                            >
                                              <Box className="chat-data-con">
                                                {showTimeAndName && (
                                                  <div className={`${!isSentByLoggedInUser ? "message-time-2 mb-1" : "mb-1"}`}>
                                                    <Typography variant="caption">
                                                      {!isSentByLoggedInUser && (
                                                        <>
                                                          <span className="message-sender message-sender-name">
                                                            {chat?.sender_detail?.name}
                                                          </span>
                                                          <span className="dot-separator">•</span>
                                                        </>
                                                      )}
                                                      <span className="dot-separator">
                                                        {formatTimestamp(chat?.message_time)}
                                                      </span>
                                                    </Typography>
                                                  </div>
                                                )}


                                                <Box className="d-flex gap-1" onMouseLeave={() => {

                                                }}>
                                                  {!isSentByLoggedInUser && (
                                                    <Avatar
                                                      aria-label="recipe"
                                                      className="avatar-chat text-capitalize"
                                                      src={chat?.sender_detail?.thumbnail}
                                                    >
                                                      {chat?.sender_detail?.name?.charAt(0)}
                                                    </Avatar>
                                                  )}

                                                  {chat?.quote_message ? (
                                                    <div className="pl__chat-wrap-reply cursor-pointer"
                                                      onMouseEnter={(e) => handlePopoverOpen(e, chat.id, isSentByLoggedInUser === true ? true : false, chat?.message)}
                                                    >
                                                      <Box className=" gap-1 reaction-popup-card" sx={{ display: 'flex', gap: '5px', background: 'white', border: '1px solid #EEEEEE', borderRadius: '6px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}
                                                        aria-controls={openMenuForChatEdit ? "account-create-content" : undefined}
                                                        aria-expanded={openMenuForChatEdit ? "true" : undefined}
                                                        aria-describedby={idForChatEdit}
                                                      >
                                                        <Box className="reaction-options" sx={{ padding: '5px', }}>

                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "love", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faHeart} className="reaction-icon fa-love-btn" />
                                                          </Box>
                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "smile", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faSmile} className="reaction-icon fa-smile-btn" />
                                                          </Box>
                                                          <Box onClick={() => {
                                                            toggleReaction(currentChatID, "angry", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faAngry} className="reaction-icon fa-angry-btn" />
                                                          </Box>
                                                          <Box className="" onClick={() => {
                                                            toggleReaction(currentChatID, "like", true)
                                                          }}>
                                                            <FontAwesomeIcon icon={faThumbsUp} className="reaction-icon fa-like-btn" />
                                                          </Box>


                                                        </Box>
                                                        {isEditableChat === true ? (
                                                          <><Divider orientation="vertical" variant="middle" flexItem /><IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-haspopup="true"
                                                            className="icon-btn-reaction"
                                                            ref={moreVertRef}
                                                            onMouseEnter={handleMouseEnter}
                                                          >
                                                            <MoreVertIcon />
                                                            <Box className={`edit-chat-popup`}
                                                            >
                                                              <List className="list-item-menu-2">
                                                                <ListItem
                                                                  key={`leave-channel-edit-${chat?.id}`}
                                                                  disablePadding
                                                                  sx={{ display: "block" }}
                                                                >
                                                                  <ListItemButton
                                                                    sx={{
                                                                      minHeight: 48,
                                                                      justifyContent: "initial",
                                                                      px: 2.5,
                                                                      "&:hover": {
                                                                        backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                                      },
                                                                    }}
                                                                    onClick={() => {
                                                                      setIsSelectedEdit(true)
                                                                      handleClickOnEditChat(chat?.message, true, chat?.id)
                                                                    }}
                                                                    className="edit-btns"
                                                                  >
                                                                    <ListItemIcon
                                                                      sx={{
                                                                        minWidth: 0,
                                                                        mr: 2,
                                                                        ml: 1,
                                                                        justifyContent: "center",
                                                                      }}
                                                                    >
                                                                      <FontAwesomeIcon
                                                                        className="edit-popover-icon"
                                                                        icon={faPen}
                                                                      />

                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                      primary="Edit"
                                                                      className="edit-popover-text"
                                                                    />
                                                                  </ListItemButton>
                                                                </ListItem>

                                                                <ListItem
                                                                  key={`leave-channel-delete-${chat?.id}`}
                                                                  disablePadding
                                                                  sx={{ display: "block" }}
                                                                >
                                                                  <ListItemButton
                                                                    sx={{
                                                                      minHeight: 48,
                                                                      justifyContent: "initial",
                                                                      px: 2.5,
                                                                      "&:hover": {
                                                                        backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                                      },
                                                                    }}
                                                                    onClick={() => {
                                                                      setIsSelectedEdit(true)
                                                                      handleDeleteChatModel()
                                                                    }}
                                                                    className="edit-btns"
                                                                  >
                                                                    <ListItemIcon
                                                                      sx={{
                                                                        minWidth: 0,
                                                                        mr: 2,
                                                                        ml: 1,
                                                                        justifyContent: "center",
                                                                      }}
                                                                    >
                                                                      <FontAwesomeIcon
                                                                        className="leave-channel-icon"
                                                                        icon={faTrash}
                                                                      />

                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                      primary="Delete"
                                                                      className="leave-channel-text"
                                                                    />
                                                                  </ListItemButton>
                                                                </ListItem>
                                                                <ListItem
                                                                  key={`leave-channel-reply-${chat?.id}`}
                                                                  disablePadding
                                                                  sx={{ display: "block" }}
                                                                >
                                                                  <ListItemButton
                                                                    sx={{
                                                                      minHeight: 48,
                                                                      justifyContent: "initial",
                                                                      px: 2.5,
                                                                      "&:hover": {
                                                                        backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                                      },
                                                                    }}
                                                                    onClick={() => {
                                                                      handleClickOnReply(chat?.id, chat?.sender_detail?.full_name, linkifyMessage(chat?.message))
                                                                    }}
                                                                    className="edit-btns"
                                                                  >
                                                                    <ListItemIcon
                                                                      sx={{
                                                                        minWidth: 0,
                                                                        mr: 2,
                                                                        ml: 1,
                                                                        justifyContent: "center",
                                                                      }}
                                                                    >
                                                                      <FontAwesomeIcon
                                                                        className="edit-popover-icon"
                                                                        icon={faReply}
                                                                      />

                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                      primary="Reply"
                                                                      className="edit-popover-text"
                                                                    />
                                                                  </ListItemButton>
                                                                </ListItem>
                                                              </List>
                                                            </Box>
                                                          </IconButton></>
                                                        ) : isEditableChat === false ? (
                                                          <><Divider orientation="vertical" variant="middle" flexItem /><IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-haspopup="true"
                                                            className="icon-btn-reaction-2"
                                                            ref={moreVertRef}
                                                            onMouseEnter={handleMouseEnter}
                                                          >
                                                            <MoreVertIcon />
                                                            <Box className={`edit-chat-popup`}
                                                            >
                                                              <List className="list-item-menu-2">
                                                                <ListItem
                                                                  key={`leave-channel-reply-${chat?.id}`}
                                                                  disablePadding
                                                                  sx={{ display: "block" }}
                                                                >
                                                                  <ListItemButton
                                                                    sx={{
                                                                      minHeight: 48,
                                                                      justifyContent: "initial",
                                                                      "&:hover": {
                                                                        backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                                      },
                                                                    }}
                                                                    onClick={() => {
                                                                      handleClickOnReply(chat?.id, chat?.sender_detail?.full_name, linkifyMessage(chat?.message))
                                                                    }}
                                                                    className="edit-btns"
                                                                  >
                                                                    <ListItemIcon
                                                                      sx={{
                                                                        minWidth: 0,
                                                                        mr: 2,
                                                                        ml: 1,
                                                                        justifyContent: "center",
                                                                      }}
                                                                    >
                                                                      <FontAwesomeIcon
                                                                        className="edit-popover-icon"
                                                                        icon={faReply}
                                                                      />

                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                      primary="Reply"
                                                                      className="edit-popover-text"
                                                                    />
                                                                  </ListItemButton>
                                                                </ListItem>
                                                              </List>
                                                            </Box>
                                                          </IconButton></>
                                                        ) : ""}

                                                      </Box>
                                                      <div className="pl__msg-replay"
                                                        onClick={() => {
                                                          setRedirectionChatID(chat?.quote_message?.message_id)
                                                        }}
                                                      >
                                                        <div className="pl__msg-block">
                                                          <p className="pl__person-name"><span>{chat?.quote_message?.sender_detail?.full_name?.charAt(0)}</span>{chat?.quote_message?.sender_detail?.full_name}</p>
                                                          <p className="pl__msg-deatils">{chat?.quote_message?.message}</p>
                                                        </div>

                                                      </div>
                                                      <Box className="msgs"
                                                        onClick={() => {
                                                          setRedirectionChatID(chat?.quote_message?.message_id)
                                                        }}
                                                      >
                                                        <Box className="d-flex gap-2 justify-content-center align-items-center w-100">

                                                          <div className={`message-content-con `} key={chat?.id}
                                                            onMouseEnter={(e) => handlePopoverOpen(e, chat.id, isSentByLoggedInUser === true ? true : false, chat?.message)}
                                                          >
                                                            <Typography
                                                              className={`${highlighted ? 'highlight' : ''} ${navigated ? 'navigated' : ''}`}
                                                              dangerouslySetInnerHTML={{ __html: linkifyMessage(chat?.message) }}
                                                            />


                                                          </div>
                                                          {chat?.sender_id === user?.id && !chat?.isSent && (
                                                            <FontAwesomeIcon icon={faClock} className="fa-clock-icon-send" />
                                                          )}
                                                        </Box>

                                                      </Box>
                                                    </div>
                                                  ) : (
                                                    <Box className="msgs"
                                                    >
                                                      <Box className="d-flex gap-2 justify-content-center align-items-center">

                                                        <div className={`message-content-con `} key={chat?.id}
                                                          onMouseEnter={(e) => handlePopoverOpen(e, chat.id, isSentByLoggedInUser === true ? true : false, chat?.message)}
                                                        >
                                                          <Typography
                                                            className={`${highlighted ? 'highlight' : ''} ${navigated ? 'navigated' : ''}`}
                                                            dangerouslySetInnerHTML={{ __html: linkifyMessage(chat?.message) }}
                                                          />
                                                          <Box className=" gap-1 reaction-popup-card" sx={{ display: 'flex', gap: '5px', background: 'white', border: '1px solid #EEEEEE', borderRadius: '6px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}
                                                            aria-controls={openMenuForChatEdit ? "account-create-content" : undefined}
                                                            aria-expanded={openMenuForChatEdit ? "true" : undefined}
                                                            aria-describedby={idForChatEdit}
                                                          >
                                                            <Box className="reaction-options" sx={{ padding: '5px', }}>

                                                              <Box onClick={() => {
                                                                toggleReaction(currentChatID, "love", true)
                                                              }}>
                                                                <FontAwesomeIcon icon={faHeart} className="reaction-icon fa-love-btn" />
                                                              </Box>
                                                              <Box onClick={() => {
                                                                toggleReaction(currentChatID, "smile", true)
                                                              }}>
                                                                <FontAwesomeIcon icon={faSmile} className="reaction-icon fa-smile-btn" />
                                                              </Box>
                                                              <Box onClick={() => {
                                                                toggleReaction(currentChatID, "angry", true)
                                                              }}>
                                                                <FontAwesomeIcon icon={faAngry} className="reaction-icon fa-angry-btn" />
                                                              </Box>
                                                              <Box className="" onClick={() => {
                                                                toggleReaction(currentChatID, "like", true)
                                                              }}>
                                                                <FontAwesomeIcon icon={faThumbsUp} className="reaction-icon fa-like-btn" />
                                                              </Box>


                                                            </Box>
                                                            {isEditableChat === true ? (
                                                              <><Divider orientation="vertical" variant="middle" flexItem /><IconButton
                                                                aria-label="more"
                                                                id="long-button"
                                                                aria-haspopup="true"
                                                                className="icon-btn-reaction"
                                                                ref={moreVertRef}
                                                                onMouseEnter={handleMouseEnter}
                                                              >
                                                                <MoreVertIcon />
                                                                <Box className={`edit-chat-popup`}
                                                                >
                                                                  <List className="list-item-menu-2">
                                                                    <ListItem
                                                                      key={`leave-channel-edit-${chat?.id}`}
                                                                      disablePadding
                                                                      sx={{ display: "block" }}
                                                                    >
                                                                      <ListItemButton
                                                                        sx={{
                                                                          minHeight: 48,
                                                                          justifyContent: "initial",
                                                                          px: 2.5,
                                                                          "&:hover": {
                                                                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                                          },
                                                                        }}
                                                                        onClick={() => {
                                                                          setIsSelectedEdit(true)
                                                                          handleClickOnEditChat(chat?.message, true, chat?.id)
                                                                        }}
                                                                        className="edit-btns"
                                                                      >
                                                                        <ListItemIcon
                                                                          sx={{
                                                                            minWidth: 0,
                                                                            mr: 2,
                                                                            ml: 1,
                                                                            justifyContent: "center",
                                                                          }}
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            className="edit-popover-icon"
                                                                            icon={faPen}
                                                                          />

                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                          primary="Edit"
                                                                          className="edit-popover-text"
                                                                        />
                                                                      </ListItemButton>
                                                                    </ListItem>

                                                                    <ListItem
                                                                      key={`leave-channel-delete-${chat?.id}`}
                                                                      disablePadding
                                                                      sx={{ display: "block" }}
                                                                    >
                                                                      <ListItemButton
                                                                        sx={{
                                                                          minHeight: 48,
                                                                          justifyContent: "initial",
                                                                          px: 2.5,
                                                                          "&:hover": {
                                                                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                                          },
                                                                        }}
                                                                        onClick={() => {
                                                                          setIsSelectedEdit(true)
                                                                          handleDeleteChatModel()
                                                                        }}
                                                                        className="edit-btns"
                                                                      >
                                                                        <ListItemIcon
                                                                          sx={{
                                                                            minWidth: 0,
                                                                            mr: 2,
                                                                            ml: 1,
                                                                            justifyContent: "center",
                                                                          }}
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            className="leave-channel-icon"
                                                                            icon={faTrash}
                                                                          />

                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                          primary="Delete"
                                                                          className="leave-channel-text"
                                                                        />
                                                                      </ListItemButton>
                                                                    </ListItem>
                                                                    <ListItem
                                                                      key={`leave-channel-reply-${chat?.id}`}
                                                                      disablePadding
                                                                      sx={{ display: "block" }}
                                                                    >
                                                                      <ListItemButton
                                                                        sx={{
                                                                          minHeight: 48,
                                                                          justifyContent: "initial",
                                                                          px: 2.5,
                                                                          "&:hover": {
                                                                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                                          },
                                                                        }}
                                                                        onClick={() => {
                                                                          handleClickOnReply(chat?.id, chat?.sender_detail?.full_name, linkifyMessage(chat?.message))
                                                                        }}
                                                                        className="edit-btns"
                                                                      >
                                                                        <ListItemIcon
                                                                          sx={{
                                                                            minWidth: 0,
                                                                            mr: 2,
                                                                            ml: 1,
                                                                            justifyContent: "center",
                                                                          }}
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            className="edit-popover-icon"
                                                                            icon={faReply}
                                                                          />

                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                          primary="Reply"
                                                                          className="edit-popover-text"
                                                                        />
                                                                      </ListItemButton>
                                                                    </ListItem>
                                                                  </List>
                                                                </Box>
                                                              </IconButton></>
                                                            ) : isEditableChat === false ? (
                                                              <><Divider orientation="vertical" variant="middle" flexItem /><IconButton
                                                                aria-label="more"
                                                                id="long-button"
                                                                aria-haspopup="true"
                                                                className="icon-btn-reaction-2"
                                                                ref={moreVertRef}
                                                                onMouseEnter={handleMouseEnter}
                                                              >
                                                                <MoreVertIcon />
                                                                <Box className={`edit-chat-popup`}
                                                                >
                                                                  <List className="list-item-menu-2">
                                                                    <ListItem
                                                                      key={`leave-channel-reply-${chat?.id}`}
                                                                      disablePadding
                                                                      sx={{ display: "block" }}
                                                                    >
                                                                      <ListItemButton
                                                                        sx={{
                                                                          minHeight: 48,
                                                                          justifyContent: "initial",
                                                                          // px: 2.5,
                                                                          "&:hover": {
                                                                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                                          },
                                                                        }}
                                                                        onClick={() => {
                                                                          handleClickOnReply(chat?.id, chat?.sender_detail?.full_name, linkifyMessage(chat?.message))
                                                                        }}
                                                                        className="edit-btns"
                                                                      >
                                                                        <ListItemIcon
                                                                          sx={{
                                                                            minWidth: 0,
                                                                            mr: 2,
                                                                            ml: 1,
                                                                            justifyContent: "center",
                                                                          }}
                                                                        >
                                                                          <FontAwesomeIcon
                                                                            className="edit-popover-icon"
                                                                            icon={faReply}
                                                                          />

                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                          primary="Reply"
                                                                          className="edit-popover-text"
                                                                        />
                                                                      </ListItemButton>
                                                                    </ListItem>
                                                                  </List>
                                                                </Box>
                                                              </IconButton></>
                                                            ) : ""}

                                                          </Box>

                                                        </div>
                                                        {chat?.sender_id === user?.id && !chat?.isSent && (
                                                          <FontAwesomeIcon icon={faClock} className="fa-clock-icon-send" />
                                                        )}
                                                      </Box>

                                                    </Box>
                                                  )}


                                                </Box>
                                                <Box className="d-flex gap-2 reaction-main-div">
                                                  {Object.entries(chat?.total_reaction || {}).map(([reactionType, reactionsArray]) => (
                                                    <Box className="reaction" key={reactionType}
                                                      aria-owns={openForPopupUser ? 'mouse-over-popover' : undefined}
                                                      aria-haspopup="true"
                                                      onMouseEnter={(e) => handlePopoverOpenForUserReactionList(e, reactionsArray)}
                                                      onMouseLeave={handlePopoverCloseForUserReactionList}
                                                    >
                                                      <HtmlTooltip
                                                        title={
                                                          <React.Fragment>
                                                            <List>
                                                              {reactionsArray?.map(
                                                                (manager, index) => (
                                                                  <>
                                                                    <ListItem>
                                                                      <ListItemAvatar>
                                                                        <Avatar
                                                                          alt={manager?.reaction_user?.name}
                                                                          src={manager?.reaction_user?.thumbnail}
                                                                          className="author-avtar-creator"
                                                                        />
                                                                      </ListItemAvatar>
                                                                      <ListItemText
                                                                        primary={manager?.reaction_user?.full_name}
                                                                      />
                                                                    </ListItem>
                                                                    {index !==
                                                                      reactionsArray?.length - 1 && (
                                                                        <Divider component="li" />
                                                                      )}
                                                                  </>
                                                                )
                                                              )}
                                                            </List>
                                                          </React.Fragment>
                                                        }
                                                        placement="top"
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={
                                                            reactionType === "love" ? faHeart :
                                                              reactionType === "smile" ? faSmile :
                                                                reactionType === "angry" ? faAngry :
                                                                  reactionType === "like" ? faThumbsUp :
                                                                    ""}
                                                          className={
                                                            reactionType === "love" ? "fa-love-btn-con" :
                                                              reactionType === "smile" ? "fa-smile-btn-con" :
                                                                reactionType === "angry" ? "fa-angry-btn-con" :
                                                                  reactionType === "like" ? "fa-like-btn-con" :
                                                                    ""}
                                                        />
                                                        <span>{reactionsArray.length}</span>
                                                      </HtmlTooltip>
                                                    </Box>
                                                  ))}
                                                </Box>
                                                {isSentByLoggedInUser && chat?.id === lastMessageSentByUser?.id && selectedChatRoomData?.room_details?.type === "group" && (
                                                  <Box className="mt-0">
                                                    {chat?.seen_by?.length > 0 && (
                                                      <Typography className="seen-all-text">Seen by: {chat?.seen_by?.map(user => user?.name).join(", ")}</Typography>

                                                    )}
                                                  </Box>
                                                )}
                                              </Box >
                                            </div>
                                          </Box>
                                        </>
                                      );
                                    })}
                                  </div>
                                )

                              })}

                            </div>
                          );
                        })}

                        {targetChatId && (
                          <Box className="load-more-btn-div">
                            {hasMoreMessagesDown && (
                              <Button onClick={loadMoreMessagesDown} disabled={isLoadingMoreDown} className="load-more-btn text-capitalize" >
                                <FontAwesomeIcon icon={faAngleDown} />
                                {isLoadingMoreDown ? "Loading..." : "Load More"}
                              </Button>

                            )}
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <div className="no-data mt-5">
                        <div>
                          <img src={noChatImg} className="text-center mx-auto no-chat-img mt-4" />
                        </div>
                      </div>
                    )}

                    {userTypingMessageData && (
                      <Box className="typing-indicator text-center">
                        <Box className="chat-msg-wrap">
                          <Typography className="typing-indicator-text">
                            {userTypingMessageData}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {isReplyQuote === true ? (
                      <div className="pl__chat-wrap">
                        <div className="pl__msg-replay">
                          <div className="pl__msg-block">
                            <p className="pl__person-name"><span>{replyquoteUser?.charAt(0)}</span>{replyquoteUser}</p>
                            <p className="pl__msg-deatils">{replyQuoteMessage}</p>
                          </div>
                          <div className="pl__close-btn cursor-pointer" onClick={handleCloseForReplyQuote}>
                            <span>X</span>
                          </div>
                        </div>
                        <Box sx={{ padding: "15px" }} className="user-chat-send-div">
                          <Box className="serach-box w-100">
                            <InputGroup className="">
                              <TextareaAutosize maxRows={10}
                                placeholder="Send message..."
                                aria-describedby="basic-addon2"
                                className={`search-input-2 w-100 ${borderOnSendMessage === true && "border-send-message-div"}`}
                                onChange={(e) => {
                                  setTypedMessage(e.target.value)
                                  handleTyping()
                                }}
                                onKeyDown={handleKeyDown}
                                as="textarea"

                                value={typedMessage}
                              />
                              <Button className="search-btn-dash-4" onClick={() => {
                                if (isEditableChat === true && currentChatID !== undefined) {
                                  updateMessage()
                                } else {
                                  sendMessage()
                                }
                              }}>
                                <FontAwesomeIcon icon={faTelegram} />
                              </Button>
                            </InputGroup>
                          </Box>
                        </Box>
                      </div>
                    ) : (
                      <Box sx={{ padding: "15px" }} className="user-chat-send-div">
                        <Box className="serach-box w-100">
                          <InputGroup className="">
                            <TextareaAutosize maxRows={10}
                              ref={messageInputRef}
                              placeholder="Send message..."
                              aria-describedby="basic-addon2"
                              className={`search-input-2 w-100 ${borderOnSendMessage === true && "border-send-message-div"}`}
                              onChange={(e) => {
                                setTypedMessage(e.target.value)
                                handleTyping()
                              }}
                              onKeyDown={handleKeyDown}
                              as="textarea"

                              value={typedMessage}
                            />
                            <Button className="search-btn-dash-4" onClick={() => {
                              if (isEditableChat === true && currentChatID !== undefined) {
                                updateMessage()
                              } else {
                                sendMessage()
                              }
                            }}>
                              <FontAwesomeIcon icon={faTelegram} />
                            </Button>
                          </InputGroup>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {isChatRooomLoading && (<ChatSkleton />)}
            </Box>
          </Box >

        </Container >
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openConversationModel}
          maxWidth="sm"
          className="conversation-dialog"
          fullWidth={true}

        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            {isEditConversation === true ? "Edit Conversation" : "Create Conversation"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseConversationModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="create-conversation-dialog">
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="box-attribute-2"
              >
              </Box>
              <Paper sx={{ width: "100%", padding: "0px 15px 15px 15px" }} className="type-list-paper-2">
                {isEditConversation === false && (
                  <Box className="py-2 px-3 mt-2">
                    <Typography className="fw-400 dark-text font-inter">
                      Conversation Type
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="categoryType"
                      className="mt-1"
                      value={conversationType}
                      onChange={handleChangeForConversationType}

                    >
                      <FormControlLabel
                        key="single"
                        value="single"
                        control={
                          <Radio
                            size="small"
                            checked={
                              conversationType ===
                              "single"
                            }
                            value="single"
                          />
                        }
                        label="Single-Conversation"
                        className="font-inter"
                      />
                      <FormControlLabel
                        key="group"
                        value="group"
                        control={
                          <Radio
                            size="small"
                            checked={
                              conversationType ===
                              "group"
                            }
                            value="group"
                          />
                        }
                        label="Group-Conversation"
                        className="font-inter"
                      />

                    </RadioGroup>
                  </Box>
                )}

                <Box sx={{}} className="py-1 px-3 mt-3">
                  {conversationType === "single" ? (
                    <>
                      <Typography className="fw-400 fs-14 dark-text">
                        Select User
                      </Typography><Select
                        closeMenuOnSelect={true}
                        options={getAllUsers}
                        menuPlacement="bottom"
                        name="fieldType"
                        value={getAllUsers.find(
                          (option) => option === selectedUser
                        )}
                        placeholder="Select the user..."
                        onChange={(selectedOption) => {
                          setSelectedUser(selectedOption);
                        }}
                        className="muilt-select-field mt-2 z-3"
                        classNamePrefix="select"
                        styles={{
                          option: (provided, state) => {
                            return {
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#660c605c"
                                : provided.backgroundColor,
                              color: state.isSelected
                                ? "var(--dark-color)"
                                : provided.color,
                            };
                          },
                        }} />
                    </>
                  ) : conversationType === "group" ? (
                    <>

                      <Typography className="fw-400 dark-text font-inter">
                        Group Name
                        <span className="required">*</span>
                      </Typography>
                      <TextField
                        className="input-field mt-2"
                        fullWidth
                        id="outlined-basic username"
                        variant="outlined"
                        size="small"
                        name="groupname"
                        placeholder="Enter group name here..."
                        value={groupName}
                        onChange={(event) => {
                          setGroupName(event?.target?.value)
                        }}

                      />
                      <Typography className="fw-400 fs-14 mt-2 dark-text">
                        Add Users
                      </Typography>
                      <Select
                        closeMenuOnSelect={false}
                        options={getAllGroupUsers}
                        menuPlacement="bottom"
                        name="fieldType"
                        value={selectedGroupUsers}
                        isMulti
                        placeholder="Select the users"
                        onChange={(selectedOption) => {
                          setSelectedGroupUsers(selectedOption);
                        }}
                        className="muilt-select-field mt-2 z-2 fs-14"
                        classNamePrefix="select"
                        styles={{
                          option: (provided, state) => {
                            return {
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "#660c605c"
                                : provided.backgroundColor,
                              color: state.isSelected
                                ? "var(--dark-color)"
                                : provided.color,
                            };
                          },
                        }} />
                      <Col lg="12" className="repeatable-col">
                        <TableContainer className="z-0">
                          <Table
                            aria-labelledby="tableTitle"
                            stickyHeader
                          >
                            <TableHeaderForSorting
                              columns={columns_atttributes_headers}
                            />
                            <TableBody>
                              {selectedGroupUsers?.length > 0 && (
                                <>
                                  {selectedGroupUsers?.map(
                                    (permission, index) => (
                                      <>
                                        <TableRow
                                          hover
                                          role="checkbox"
                                          tabIndex={-1}
                                          key={permission.value}
                                          className="cursor-pointer tbl-row-attribute-row"
                                        >
                                          <TableCell
                                            align="left"
                                            className={` tbl-border-left tbl-cell-attribute-2`}
                                            style={{
                                              top: 0,
                                              left: 0,
                                            }}
                                          >
                                            <Typography className="fs-14">
                                              {permission?.label}
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            align="right"
                                            className={`tbl-cell-attribute-2`}
                                            style={{
                                              top: 0,
                                              left: 0,
                                            }}
                                          >
                                            <AntSwitch
                                              name="view"
                                              checked={
                                                permission.is_admin
                                              }
                                              onChange={() =>
                                                handleToggle(
                                                  permission.value,
                                                  "is_admin"
                                                )
                                              }
                                              inputProps={{
                                                "aria-label":
                                                  "ant design",
                                              }}
                                              className="ms-2"
                                            />
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className={`tbl-cell-attribute-2`}
                                            style={{
                                              top: 0,
                                              left: 0,
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={
                                                faXmark
                                              }
                                              onClick={() => {
                                                removeGroupUser(permission.value)
                                              }}
                                              className={`cross-icon ms-4`}
                                            />

                                          </TableCell>

                                        </TableRow>
                                        <TableRow className="extra-row"></TableRow>
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Col>
                    </>
                  ) : ""}

                </Box>

              </Paper>
            </Box>
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-center gap-2 p-3 z-0 action-btn-model">

            <Button
              className="btn primary-btn"
              onClick={() => {
                createConversation()
              }}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              {isEditConversation === true ? "Save Settings" : "Create Conversation"}

            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openLeaveConversationAlertModel}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handlecloseLeaveConversationModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to leave the conversation?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handlecloseLeaveConversationModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                leaveConversationApi()
              }}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openAddGroupNameModel}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Add Conversation
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseAddGroupNameModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            <Typography className="fw-400 dark-text font-inter">
              Group Name
              <span className="required">*</span>
            </Typography>
            <TextField
              className="input-field mt-2"
              fullWidth
              id="outlined-basic username"
              variant="outlined"
              size="small"
              name="groupname"
              placeholder="Enter group name here..."
              value={groupName}
              onChange={(event) => {
                setGroupName(event?.target?.value)
              }}

            />

          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseAddGroupNameModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                createConversation()
              }}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteChatAlertModel}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handlecloseDeleteChatModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to delete this messsage?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handlecloseDeleteChatModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => {
                deleteMessageAPI()
              }}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Popover
          id={idStartConversation}
          open={openMenuForSatrtConversation}
          anchorEl={anchorElForStartConversation}
          onClose={handleCloseForStartConversation}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="create-con-popover"
        >
          <Box>
            <Box className="p-3">
              <TextField
                className="input-field"
                fullWidth
                id="outlined-basic username"
                variant="outlined"
                size="small"
                name="groupname"
                placeholder="Search Users..."
                value={searchUsers}
                onChange={(event) => {
                  setIsSearchTyping(true)
                  setSerachUsers(event?.target?.value)
                }}

              />
            </Box>
            <Divider style={{ background: "#EEEEEE" }} />
            <Box sx={{ height: "300px", overflow: "auto" }}>
              <Box className="main-box-2">
                {getUserList?.map((user, index) => (
                  <>
                    <Box className="main-div-user-list cursor-pointer" sx={{ padding: "8px 0 8px 0" }} onClick={() => handleUserClick(user)}>
                      <Box className="user-details-div">
                        <Stack
                          direction="row"
                          alignContent="center"
                          alignItems="center"
                          spacing={1}
                          className="z-n1"
                        >
                          <Avatar
                            alt={user?.name}
                            src={user?.thumbnail}
                            className="author-avtar"
                          >
                            {user?.name?.charAt(0)}
                          </Avatar>
                          <Typography className="fs-14-muted ">
                            {user?.full_name}
                          </Typography>
                        </Stack>
                      </Box>

                      <Box className="user-checkmark-div">
                        {selectedUsers.some((u) => u.id === user.id) && (
                          <FontAwesomeIcon icon={faCheck} className="selected-users" />
                        )}
                      </Box>


                    </Box>

                    <Divider style={{ background: "#EEEEEE" }} />
                  </>
                ))}

              </Box>
            </Box>
            <Divider />
            <Box className="p-3">
              <Button
                className="btn primary-btn"
                onClick={() => {
                  if (selectedUsers?.length < 2 && selectedUsers?.length === 1) {
                    createConversation()
                  } else if (selectedUsers?.length > 1) {
                    const users = selectedUsers?.map((user) => user?.name)
                    const dataUser = users?.join(",")
                    setGroupName(dataUser)
                    setOpenGroupNameModel(true)
                  } else if (selectedUsers?.length === 0) {
                    Toast.error("Please select any user to create chat!")
                  }
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
                {selectedUsers?.length > 1 ? "Create Group Chat" : "Create Chat"}

              </Button>
            </Box>
          </Box>
        </Popover>
        <Popover
          id={idForEdit}
          open={openMenuForEdit}
          anchorEl={anchorElForEdit}
          onClose={handleCloseForEdit}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="create-con-popover"
        >
          <Box>
            <List className="list-item-menu-2">
              {selectedChatRoomData?.room_details?.is_admin === 1 && (
                <ListItem
                  key="chat-settings"
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      px: 2.5,
                      "&:hover": {
                        backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                      },
                    }}
                    onClick={() => {
                      setIsEditConversation(true)
                      fetchConversationDetails()
                    }}
                    className={"item-div"}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: 2,
                        ml: 4,
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        className="edit-popover-icon"
                        icon={faGears}
                      />

                    </ListItemIcon>
                    <ListItemText
                      primary="Chat Settings"
                      className="edit-popover-text"
                    />
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem
                key="leave-channel"
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                    "&:hover": {
                      backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                    },
                  }}
                  onClick={() => {
                    handleLeaveConversation()
                  }}
                  className={"item-div"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: 2,
                      ml: 4,
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      className="leave-channel-icon"
                      icon={faArrowLeft}
                    />

                  </ListItemIcon>
                  <ListItemText
                    primary="Leave channel"
                    className="leave-channel-text"
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Popover>

        <Loader isLoading={loading} />
        <Toaster />
      </div >
    </>
  )
}

export default Conversation