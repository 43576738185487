import React, { useEffect, useRef, useState, useCallback } from "react";
import { Container, Image, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faFolder,
    faList,
    faPause,
    faPlay,
    faFilePdf,
    faFile,
    faFileExcel,
    faGears,
    faFolderOpen,
    faVideo,
    faPen,
    faChevronDown,
    faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import logoutHelper from "../../helpers/logoutHelper";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import {
    Box,
    Stack,
    Breadcrumbs,
    Link,
    Divider,
    Button,
    IconButton,
    Typography,
    Chip,
    CircularProgress,
    TextField,
    Checkbox,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Grid
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toaster } from "react-hot-toast";
import { Toast } from "../../utils/toast";

import "../../styles/media.scss";
import { useDropzone } from "react-dropzone";
import AddFolder from "../../components/common/media/addFolder";
import AddMedia from "../../components/common/media/addMedia";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const columns_atttributes_headers = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 250,
        className: "other-color",
    },
    {
        key: 2,
        id: "view",
        disablePadding: true,
        label: "View",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 3,
        id: "deny",
        disablePadding: true,
        label: "Deny",
        minWidth: 50,
        className: "other-color",
    },
];

const columns_advace_atttributes_headers = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 250,
        className: "other-color",
    },
    {
        key: 2,
        id: "view",
        disablePadding: true,
        label: "View",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 3,
        id: "deny",
        disablePadding: true,
        label: "Deny",
        minWidth: 50,
        className: "other-color",
    },

    {
        key: 5,
        id: "edit",
        disablePadding: true,
        label: "Edit",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 6,
        id: "delete",
        disablePadding: true,
        label: "Delete",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 7,
        id: "redacted",
        disablePadding: true,
        label: "Redacted",
        minWidth: 50,
        className: "other-color",
    },
];

const AdminMedia = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userToken = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);

    const [loading, setLoading] = useState(false);
    const [fetchAllUsers, setFetchAllUsers] = useState([])
    const [getAllMedia, setGetAllMedia] = useState([]);
    const [getAllMediaResponse, setGetAllMediaResponse] = useState()
    const [breadcrumbData, setBreadcrumbData] = useState({ 0: "Media" });
    const [selectedTabName, setSelectedTabName] = useState("all");
    const [selectedMedia, setSelectedMedia] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const [editMediaType, setEditMediType] = useState("")
    const [page, setPage] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [search, setSearch] = useState("");
    const [istype, setIstype] = useState(false);

    const [openAddFolder, setOpenAddFolder] = useState(false)
    const [newFolderName, setNewFolderName] = useState("")
    const [isSystemTypeFolder, setIsSystemTypeFolder] = useState(false)
    const [responseForFolder, setResponseForFolder] = useState()

    const [mediaTitle, setMediaTitle] = useState("")
    const [mediaAltText, setMediaAltText] = useState("")
    const [mediaDescription, setMediaDescription] = useState("")
    const [mediaDirectUrl, setMediaDirectUrl] = useState("")
    const [isSystemTypeMedia, setIsSystemTypeMedia] = useState(false)
    const [responseForMedia, setResponseForMedia] = useState()

    const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
    const [contentForPost, setContentForPost] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [isChild, setIsChild] = useState(false)
    const [parentFolderId, setParentFolderId] = useState(0)
    const [isParentFolderSystemType, setIsParentFolderSystemType] = useState(false)

    const [valueForFolderTab, setValueForFolderTab] = useState(0);
    const [valueForMediaTab, setValueForMediaTab] = useState(0);
    const [valueForListingMedia, setValueForListingMedia] = useState(0);

    const [getAllGroupsAndUsersForFolder, setGetAllGroupsAndUsersForFolder] = useState([]);
    const [permissionsForFolder, setPermissionsForFolder] = useState([]);

    const [isAdvancePermissionForFolder, setIsAdvancePermissionForFolder] = useState(false);
    const [isAdvancePermissionForMedia, setIsAdvancePermissionForMedia] = useState(false);

    const [getAllGroupsAndUsersForMedia, setGetAllGroupsAndUsersForMedia] = useState([]);
    const [permissionsForMedia, setPermissionsForMedia] = useState([]);

    const imageFileTypes = ["JPG", "PNG", "GIF"];
    const fileTypes = ["docx", "pdf", "JPG", "PNG", "GIF", "ppt", "mp4"];

    const totalMedia = parseInt(getAllMediaResponse?.meta?.total);
    const recordsPerPage = getAllMediaResponse?.meta?.per_page;
    const totalPages = Math.ceil(totalMedia / recordsPerPage);

    const {
        open,
        selectedItem,
        nameCollapsable,
        typeCollapsable,
        activeType,
        drawerVariant,
        usersCollapsable,
    } = useSelector((state) => state?.drawerMenu);
    let debounceTimer;
    const debounceTimeout = 500;

    function PreviousItem(props) {
        return (
            <Button
                {...props}
                startIcon={<ArrowBackIcon />}
                className="text-capitalize fs-12-pagination pagination-btns"
            >
                Newer Media
            </Button>
        );
    }

    function NextItem(props) {
        return (
            <Button
                {...props}
                endIcon={<ArrowForwardIcon />}
                className="text-capitalize fs-12-pagination pagination-btns"
            >
                Previous Media
            </Button>
        );
    }

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setPage(newPage);
            fetchAllMedia(valueForListingMedia === 0
                ? "all"
                : valueForListingMedia === 1
                    ? "system"
                    : valueForListingMedia === 3
                        ? "user"
                        : "user", parentFolderId, newPage);
        } else if (newPage < page) {
            setPage(newPage);
            fetchAllMedia(valueForListingMedia === 0
                ? "all"
                : valueForListingMedia === 1
                    ? "system"
                    : valueForListingMedia === 3
                        ? "user"
                        : "user", parentFolderId, newPage);
        }
    };

    const updateBreadcrumbs = (folderId, folderName) => {
        setBreadcrumbData((prevData) => {
            const newBreadcrumbs = { ...prevData };

            // Check if folderId exists in the breadcrumb data
            if (newBreadcrumbs.hasOwnProperty(folderId)) {
                // If it exists, remove that entry and everything after it
                const updatedBreadcrumbs = {};
                Object.keys(newBreadcrumbs).forEach((key) => {
                    if (parseInt(key, 10) <= folderId) {
                        updatedBreadcrumbs[key] = newBreadcrumbs[key];
                    }
                });
                return updatedBreadcrumbs;
            } else {
                // If it doesn't exist, append the new folderId and folderName
                const newKey =
                    Math.max(...Object.keys(newBreadcrumbs).map(Number)) + 1;
                return { ...newBreadcrumbs, [folderId]: folderName };
            }
        });
    };

    const handleCloseAddFolderModel = () => {
        const allUserOption = fetchAllUsers.find(
            (user) => user.value == "all_users"
        );
        setOpenAddFolder(false);
        setNewFolderName("")
        setIsSystemTypeFolder(false)
        setValueForFolderTab(0)
        setPermissionsForFolder([allUserOption]);
        setIsAdvancePermissionForFolder(false)
        setIsEdit(false)
        setEditMediType("")
        setSelectedMedia("")
        setResponseForFolder()
    };

    const handleChangeForSystemFolder = () => {
        setIsSystemTypeFolder(!isSystemTypeFolder)
    }

    const handleChangeForSystemMedia = () => {
        setIsSystemTypeMedia(!isSystemTypeMedia)
    }

    const getMimeType = (extension) => {
        const mimeTypes = {
            'png': 'image/png',
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'pdf': 'application/pdf',
            'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'mp4': 'video/mp4',
            'mp3': 'audio/mpeg'
        };
        return mimeTypes[extension] || 'application/octet-stream';
    };

    const handleChangeForFolder = (event, newValue) => {
        setValueForFolderTab(newValue);
    };

    const handleChangeForMedia = (event, newValue) => {
        setValueForMediaTab(newValue);
    };

    const handleChangeForMediaList = (event, newValue) => {
        setValueForListingMedia(newValue);
        let value =
            newValue === 0
                ? "all"
                : newValue === 1
                    ? "system"
                    : newValue === 2
                        ? "user"
                        : "user";
        setSelectedTabName(value);
        setBreadcrumbData({ 0: "Media" });
        setIsChild(false)
        setParentFolderId("")
        setIsParentFolderSystemType(false)
        fetchAllMedia(value, 0, page);
    };

    const handleToggleForFolder = (value, key) => {
        setPermissionsForFolder((prevPermissions) =>
            prevPermissions?.map((permission) => {
                if (permission?.value === value) {
                    if (key === 'deny') {
                        // If deny is toggled, set all other permissions to false
                        return {
                            ...permission,
                            deny: !permission.deny,
                            view: false,
                            edit: false,
                            delete: false,
                            redacted: false,
                        };
                    } else {
                        // If any other permission is toggled, set deny to false
                        return {
                            ...permission,
                            [key]: !permission[key],
                            deny: false,
                        };
                    }
                }
                return permission;
            })
        );

        setGetAllGroupsAndUsersForFolder((prevPermissions) =>
            prevPermissions.map((permission) => {
                if (permission?.value === value) {
                    if (key === 'deny') {
                        // If deny is toggled, set all other permissions to false
                        return {
                            ...permission,
                            deny: !permission.deny,
                            view: false,
                            edit: false,
                            delete: false,
                            redacted: false,
                        };
                    } else {
                        // If any other permission is toggled, set deny to false
                        return {
                            ...permission,
                            [key]: !permission[key],
                            deny: false,
                        };
                    }
                }
                return permission;
            })
        );
    };

    const handleToggleForMedia = (value, key) => {
        setPermissionsForMedia((prevPermissions) =>
            prevPermissions?.map((permission) => {
                if (permission?.value === value) {
                    if (key === 'deny') {
                        // If deny is toggled, set all other permissions to false
                        return {
                            ...permission,
                            deny: !permission.deny,
                            view: false,
                            edit: false,
                            delete: false,
                            redacted: false,
                        };
                    } else {
                        // If any other permission is toggled, set deny to false
                        return {
                            ...permission,
                            [key]: !permission[key],
                            deny: false,
                        };
                    }
                }
                return permission;
            })
        );

        setGetAllGroupsAndUsersForMedia((prevPermissions) =>
            prevPermissions.map((permission) => {
                if (permission?.value === value) {
                    if (key === 'deny') {
                        // If deny is toggled, set all other permissions to false
                        return {
                            ...permission,
                            deny: !permission.deny,
                            view: false,
                            edit: false,
                            delete: false,
                            redacted: false,
                        };
                    } else {
                        // If any other permission is toggled, set deny to false
                        return {
                            ...permission,
                            [key]: !permission[key],
                            deny: false,
                        };
                    }
                }
                return permission;
            })
        );
    };

    const handleCloseForMediaModel = () => {
        const allUserOption = fetchAllUsers.find(
            (user) => user.value == "all_users"
        );
        setOpenFileUploadModel(false)
        setMediaTitle("")
        setMediaAltText("")
        setMediaDescription("")
        setMediaDirectUrl("")
        setIsSystemTypeMedia(false)
        setSelectedFile(null)
        setValueForMediaTab(0)
        setIsAdvancePermissionForMedia(false)
        setPermissionsForMedia([allUserOption])
        setIsEdit(false)
        setEditMediType("")
        setSelectedMedia("")
        setResponseForMedia()
    }

    const fetchGroupsAndUsersList = async () => {
        try {
            const response = await api.get(`/group-users-list`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                let acknowledgementQuestionsArray = [];

                const users = response?.response?.users;
                const groups = response?.response?.groups;

                const categories = response?.response?.category

                const finalGroups = groups?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: false,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });
                const finalUsers = users?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: false,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });
                setFetchAllUsers(finalUsers)

                const allGroupsAndUsersData = [
                    { label: "Users", options: finalUsers },
                    { label: "Groups", options: finalGroups },
                ];
                // Find the "all_user" option
                const allUserOption = finalUsers.find(
                    (user) => user.value == "all_users"
                );

                setGetAllGroupsAndUsersForFolder(allGroupsAndUsersData);
                setGetAllGroupsAndUsersForMedia(allGroupsAndUsersData)

                // Preselect "all_user"
                if (allUserOption && !isEdit) {
                    setPermissionsForFolder([allUserOption]);
                    setPermissionsForMedia([allUserOption])
                }
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/type/news`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchAllMedia = async (type, parent_folder_id, page) => {
        try {
            setLoading(true);
            const response = await api.post(
                `user/media/listing/${type}?page=${page}`,
                { folder_id: parent_folder_id, search: search },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success) {
                setGetAllMediaResponse(response?.response)
                setGetAllMedia(response?.response?.data);
            }
            setLoading(false);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.");
            }
            setLoading(false);
        }
    };

    const handleFolderClick = async (folder_id, folder_name, is_system_folder) => {
        if (folder_id == 0) {
            setIsChild(false)
            setParentFolderId("")
            setIsParentFolderSystemType(false)
        } else {
            setIsChild(true)
            setParentFolderId(folder_id)
            if (is_system_folder == true) {
                setIsParentFolderSystemType(true)
                setIsSystemTypeFolder(true)
                setIsSystemTypeMedia(true)
            } else {
                setIsParentFolderSystemType(false)
                setIsSystemTypeFolder(false)
                setIsSystemTypeMedia(false)
            }
        }

        try {
            setLoading(true);
            const response = await api.post(
                `user/media/listing/${selectedTabName}`,
                {
                    folder_id: folder_id ? folder_id : 0,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success) {
                setGetAllMedia(response?.response?.data);
                setGetAllMediaResponse(response?.response)
                updateBreadcrumbs(
                    folder_id,
                    folder_name ? folder_name : "Media"
                );
            }
            setLoading(false);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.");
            }
            setLoading(false);
        }
    };

    const fetchMediaDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/file/edit/${selectedMedia}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                const responseData = response?.response
                setLoading(false);
                setMediaTitle(responseData?.title)
                setMediaAltText(responseData?.alt_text)
                setMediaDescription(responseData?.description)
                const permissions = responseData?.permission;
                setIsSystemTypeMedia(responseData?.is_system == 0 ? false : true)
                setResponseForMedia(responseData)
                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view == 0 ? false : true,
                        deny: data?.can_deny == 0 ? false : true,
                        edit: data?.can_edit == 0 ? false : true,
                        delete: data?.can_delete == 0 ? false : true,
                        redacted: data?.can_resource == 0 ? false : true,
                        type: data?.type,
                    };
                });
                const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
                const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
                setMediaDirectUrl(responseData?.file_path)
                setPermissionsForMedia(finalPermissions)
                setIsAdvancePermissionForMedia(
                    responseData?.advance_permission == 0 ? false : true
                );
                setSelectedFile({
                    url: responseData?.file_path,
                    type: mimeType,
                    name: responseData?.title || "Uploaded File"
                });
                setOpenFileUploadModel(true)
                Toast.success(response?.message);

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchFolderDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/folder/edit/${selectedMedia}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                const responseData = response?.response
                setLoading(false);
                setNewFolderName(responseData?.title)
                const permissions = responseData?.permission;
                setIsSystemTypeFolder(responseData?.is_system == 0 ? false : true)

                setResponseForFolder(responseData)
                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view == 0 ? false : true,
                        deny: data?.can_deny == 0 ? false : true,
                        edit: data?.can_edit == 0 ? false : true,
                        delete: data?.can_delete == 0 ? false : true,
                        redacted: data?.can_resource == 0 ? false : true,
                        type: data?.type,
                    };
                });

                setPermissionsForFolder(finalPermissions)
                setIsAdvancePermissionForFolder(
                    responseData?.advance_permission == 0 ? false : true
                );
                setOpenAddFolder(true)
                Toast.success(response?.message);

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status == 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles?.length > 0) {
            const file = acceptedFiles[0];
            const fileUrl = URL?.createObjectURL(file); // Create a URL for the file

            // Set the selected file with both file object and its URL
            setSelectedFile({
                file,
                url: fileUrl,
                type: file?.type, // Get the MIME type from the dropped file
                name: file?.name // Use the file's name for display
            });
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "image/*": [], // Accept all image types
            "application/pdf": [], // Accept PDFs
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
            "video/mp4": [], // MP4
            "audio/mpeg": [], // MP3
        },
        multiple: false,
    });

    const renderPreview = () => {
        if (!selectedFile) return null;

        // Handle image files
        if (selectedFile?.type?.startsWith('image/')) {
            return (
                <div className="image-preview">
                    <img
                        src={selectedFile?.url}
                        alt="Uploaded"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Maintain aspect ratio
                        }}
                    />
                </div>
            );
        }

        // Handle PDF files
        if (selectedFile?.type === 'application/pdf') {
            // return <p>PDF file selected: {selectedFile.name}</p>;
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFilePdf
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFile?.name}
                    </Box>
                </Card>
            );
        }

        // Handle DOCX files
        if (selectedFile?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // return <p>DOCX file selected: {selectedFile.name}</p>;
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFile
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFile?.name}
                    </Box>
                </Card>
            );
        }

        // Handle PPTX files
        if (selectedFile?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFile
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFile?.name}
                    </Box>
                </Card>
            );
        }

        if (selectedFile?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFileExcel
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFile?.name}
                    </Box>
                </Card>
            );
        }

        // Handle MP4 files
        if (selectedFile?.type === 'video/mp4') {
            return (
                <Box className="w-100 h-100">
                    <Typography>{selectedFile?.name}</Typography>
                    <ReactPlayer
                        url={selectedFile?.url}
                        className="react-player"
                        playing={true}
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                </Box>
            );
        }

        // Handle MP3 files
        if (selectedFile?.type === 'audio/mpeg') {
            return (
                <Box className="w-100 h-100 audio-file">
                    <Typography>{selectedFile?.name}</Typography>
                    <ReactAudioPlayer
                        src={selectedFile?.url}
                        controls
                    />
                </Box>
            );
        }

        return (
            <Card
                key={"pdf"}
                variant="outlined"
                sx={{
                    p: 2,
                    mb: 2,
                    cursor: "pointer",
                }}
                className="pdf-doc"
            >
                <Box
                    height={150}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <FontAwesomeIcon
                        size="3x"
                        icon={
                            faFilePdf
                        }
                        color="#660C60"
                    />
                </Box>
                <Divider />
                <Box
                    sx={{
                        paddingLeft: 1,
                    }}
                >
                    {selectedFile?.name}
                </Box>
            </Card>
        );
    };

    const validateAddFolder = () => {
        let isValid = true;

        if (newFolderName === "") {
            Toast.error("Please enter a title for the folder");
            isValid = false;
        }
        if (permissionsForFolder?.length < 1) {
            Toast.error("Please select at least one user/group for access permission");
            isValid = false;
        }

        return isValid;
    };

    const validateMedia = () => {
        let isValid = true;

        if (mediaTitle === "") {
            Toast.error("Please enter a title for media");
            isValid = false;
        }
        if (mediaAltText === "") {
            Toast.error("Please enter a alt text for media");
            isValid = false;
        }
        if (mediaDescription === "") {
            Toast.error("Please enter a description for media");
            isValid = false;
        }
        if (permissionsForMedia?.length < 1) {
            Toast.error("Please select at least one user/group for access permission");
            isValid = false;
        }
        if (selectedFile === null) {
            Toast.error("Please upload a media file");
            isValid = false;
        }
        return isValid;

    }

    const storeFolder = async () => {
        const validate = await validateAddFolder()

        if (validate) {
            let permissionData;

            if (isAdvancePermissionForFolder == true) {
                permissionData = permissionsForFolder?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissionsForFolder?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }
            let data = {
                title: newFolderName,
                is_system: isSystemTypeFolder == true ? 1 : 0,
                is_child: 0,
                parent_id: 0,
                advance_permission: isAdvancePermissionForFolder == true ? 1 : 0,
                permission: JSON?.stringify(permissionData),
                is_child: isChild == true ? 1 : 0,
                parent_id: isChild == true && parentFolderId
            }

            if (isEdit == true) {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/folder/update/${selectedMedia}`,
                        data,
                        {
                            headers: {
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success == true) {
                        setLoading(false);

                        Toast.success(response?.message);
                        handleCloseAddFolderModel()
                        fetchAllMedia(
                            valueForListingMedia === 0
                                ? "all"
                                : valueForListingMedia === 1
                                    ? "system"
                                    : valueForListingMedia === 3
                                        ? "user"
                                        : "user", parentFolderId, page
                        );
                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status == 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status == 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status == 429) {
                        Toast.error("Too many attemps wait for 2 min.")
                    }
                }
            } else {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/folder/store`,
                        data,
                        {
                            headers: {
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success == true) {
                        setLoading(false);

                        Toast.success(response?.message);
                        handleCloseAddFolderModel()
                        fetchAllMedia(
                            valueForListingMedia === 0
                                ? "all"
                                : valueForListingMedia === 1
                                    ? "system"
                                    : valueForListingMedia === 3
                                        ? "user"
                                        : "user", parentFolderId, page
                        );
                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status == 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status == 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status == 429) {
                        Toast.error("Too many attemps wait for 2 min.")
                    }
                }
            }

        }

    }

    const storeMedia = async () => {
        const validate = await validateMedia()

        if (validate) {
            const formData = new FormData();
            let permissionData;
            formData.append("folder_id", "");
            formData.append("is_system", isSystemTypeMedia == true ? 1 : 0);
            formData.append("title", mediaTitle);
            formData.append("alt_text", mediaAltText);
            formData.append("description", mediaDescription);
            formData.append("advance_permission", isAdvancePermissionForMedia == true ? 1 : 0);

            if (isAdvancePermissionForMedia == true) {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }

            if (isChild == true) {
                formData.append("folder_id", parentFolderId)
            }

            if (isEdit == true) {
                formData.append("direct_url", responseForMedia?.file_path)
            }

            formData.append("permission", JSON?.stringify(permissionData));

            if (
                selectedFile?.file instanceof Blob ||
                selectedFile?.file instanceof File
            ) {
                formData.append("file", selectedFile?.file);
            }

            if (isEdit == true) {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/file/update/${selectedMedia}`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success == true) {
                        setLoading(false);

                        Toast.success(response?.message);
                        handleCloseForMediaModel()
                        fetchAllMedia(
                            valueForListingMedia === 0
                                ? "all"
                                : valueForListingMedia === 1
                                    ? "system"
                                    : valueForListingMedia === 3
                                        ? "user"
                                        : "user", parentFolderId, page
                        );
                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status == 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status == 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status == 429) {
                        Toast.error("Too many attemps wait for 2 min.")
                    }
                }
            } else {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/file/store`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success == true) {
                        setLoading(false);

                        Toast.success(response?.message);
                        handleCloseForMediaModel()
                        fetchAllMedia(
                            valueForListingMedia === 0
                                ? "all"
                                : valueForListingMedia === 1
                                    ? "system"
                                    : valueForListingMedia === 3
                                        ? "user"
                                        : "user", parentFolderId, page
                        );
                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status == 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status == 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status == 429) {
                        Toast.error("Too many attemps wait for 2 min.")
                    }
                }
            }


        }
    }

    function a11yProps7(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForListingMedia === index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps8(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForListingMedia === index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    function a11yProps9(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForListingMedia === index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    useEffect(() => {
        fetchGroupsAndUsersList()
        fetchAllMedia(
            valueForListingMedia === 0
                ? "all"
                : valueForListingMedia === 1
                    ? "system"
                    : valueForListingMedia === 3
                        ? "user"
                        : "user", parentFolderId, page
        );
    }, [])

    useEffect(() => {
        if (selectedMedia !== "" && isEdit) {
            if (editMediaType === "file") {
                fetchMediaDetails()
            } else if (editMediaType === "folder") {
                fetchFolderDetails()
            }
        }
    }, [selectedMedia])
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }
        console.log("useEffect search");
        if (search.length >= 3) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                console.log("fetchall 656")
                // fetchAllPostList(1);
                fetchAllMedia(
                    valueForListingMedia === 0
                        ? "all"
                        : valueForListingMedia === 1
                            ? "system"
                            : valueForListingMedia === 3
                                ? "user"
                                : "user", parentFolderId, page
                );
            }, debounceTimeout);
        }
        else if (search === "" && istype == true) {
            console.log("fetchall 661")
            // fetchAllPostList(1);
            fetchAllMedia(
                valueForListingMedia === 0
                    ? "all"
                    : valueForListingMedia === 1
                        ? "system"
                        : valueForListingMedia === 3
                            ? "user"
                            : "user", parentFolderId, page
            );
        }
        else {
            clearTimeout(debounceTimer);
        }

        return () => clearTimeout(debounceTimer);
    }, [search]);

    return (
        <>
            <div
                className={`main-content-wrapper ${open == true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <div className="main-div-2-types">
                        <Box className="">
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    <Link
                                        underline="hover"
                                        key="1"
                                        color="inherit"
                                        href="/"
                                        component="h3"
                                        className="cursor-pointer"
                                    >
                                        {loggedUser?.user_type == "admin" ? "Admin" : "User"}
                                    </Link>
                                    {Object.entries(breadcrumbData).map(
                                        ([key, value], index, array) => {
                                            const isLast =
                                                index === array.length - 1;
                                            return isLast ? (
                                                <Typography
                                                    key={key}
                                                    component="h3"
                                                    color="text.primary"
                                                    className="cursor-pointer"
                                                    underline="hover"
                                                >
                                                    {value}
                                                </Typography>
                                            ) : (
                                                <Link
                                                    key={key}
                                                    color="inherit"
                                                    onClick={() =>
                                                        handleFolderClick(
                                                            parseInt(key, 10),
                                                            value
                                                        )
                                                    }
                                                    component="h3"
                                                    className="cursor-pointer"
                                                    underline="hover"
                                                >
                                                    {value}
                                                </Link>
                                            );
                                        }
                                    )}
                                </Breadcrumbs>
                            </Stack>
                        </Box>
                        <div className="d-flex flex-wrap gap-3">
                            <Button className="btn export-btn" variant="outlined"
                                onClick={() => {
                                    setOpenAddFolder(true)
                                }}
                            >
                                Create Media Folder
                                <FontAwesomeIcon icon={faFolder} />
                            </Button>

                            <Button
                                className="btn primary-btn"
                                onClick={() => {
                                    setOpenFileUploadModel(true)
                                }}
                            >
                                Upload New Media
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </div>
                    </div>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                            className="box-attribute"
                        >
                            <Tabs
                                value={valueForListingMedia}
                                onChange={handleChangeForMediaList}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                className={"tabs"}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#660C60",
                                    },
                                }}
                            >
                                <Tab
                                    label="All Media"
                                    icon={<FontAwesomeIcon icon={faList} />}
                                    iconPosition="start"
                                    {...a11yProps7(0)}
                                />
                                <Tab
                                    label="System Media"
                                    {...a11yProps8(1)}
                                    icon={<FontAwesomeIcon icon={faPlay} />}
                                    iconPosition="start"
                                />

                                <Tab
                                    label="User Generated"
                                    {...a11yProps9(2)}
                                    icon={<FontAwesomeIcon icon={faPause} />}
                                    iconPosition="start"
                                />
                            </Tabs>
                        </Box>
                        {isCollapsed == true && (
                            <>
                                <Box className="">
                                    <TextField
                                        className="bg-white mt-3 mb-3"
                                        fullWidth
                                        id="outlined-basic username"
                                        variant="outlined"
                                        size="small"
                                        name="attributeTitle"
                                        value={search}
                                        placeholder="Keyword Filter"
                                        onChange={(e) => {
                                            setIstype(true)
                                            setSearch(e.target.value);
                                            if (e.target.value === "") {
                                                // fetchAllPostList(page);
                                                fetchAllMedia(
                                                    valueForListingMedia === 0
                                                        ? "all"
                                                        : valueForListingMedia === 1
                                                            ? "system"
                                                            : valueForListingMedia === 3
                                                                ? "user"
                                                                : "user", parentFolderId, page
                                                );
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                fetchAllMedia(
                                                    valueForListingMedia === 0
                                                        ? "all"
                                                        : valueForListingMedia === 1
                                                            ? "system"
                                                            : valueForListingMedia === 3
                                                                ? "user"
                                                                : "user", parentFolderId, page
                                                );
                                                // fetchAllPostList(page);
                                            }
                                        }}
                                    />
                                </Box>
                            </>
                        )}

                        <Divider className={`${isCollapsed == true ? "" : ""}`}>
                            <Button
                                onClick={() => setIsCollapsed((prevState) => !prevState)}
                                className="bg-white hide-option-btn d-flex gap-2"
                            >
                                {isCollapsed == false
                                    ? "Show Advanced Filters"
                                    : "Hide Advanced Filters"}

                                <FontAwesomeIcon
                                    icon={isCollapsed == false ? faChevronDown : faChevronUp}
                                    className="hide-option-btn-svg"
                                />
                            </Button>
                        </Divider>

                        {getAllMedia.length > 0 ? (
                            <Box sx={{ p: 2 }}>
                                <Grid container spacing={2}>
                                    {getAllMedia?.map((data, index) =>
                                        data?.type === "folder" ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={2}
                                                className="folder-grid"
                                            >
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{
                                                        p: 2,
                                                        mb: 2,
                                                        position:
                                                            "relative",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        handleFolderClick(
                                                            data?.id,
                                                            data?.title,
                                                            data?.is_system
                                                        )
                                                    }
                                                    className="card-media"
                                                >
                                                    <Box
                                                        height={150}
                                                        width={150}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faFolderOpen
                                                            }
                                                            color="#660C60"
                                                            className="folder-icon"
                                                        />
                                                    </Box>
                                                    <Divider />
                                                    <Box
                                                        className="media-item-name"
                                                    >
                                                        {data?.title}
                                                    </Box>

                                                </Card>
                                                <Box className="media-icons">
                                                    <Box className="media-icons-div">
                                                        {data?.is_system == true && (
                                                            <Box
                                                                size="small"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faGears}
                                                                    color="#660C60"
                                                                    className="system-icon"
                                                                />
                                                            </Box>
                                                        )}
                                                        {data?.edit_media == true && (
                                                            <Box
                                                                size="small"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faPen}
                                                                    color="#660C60"
                                                                    className="system-icon cursor-pointer"
                                                                    onClick={() => {
                                                                        setEditMediType("folder")
                                                                        setSelectedMedia(data?.id)
                                                                        setIsEdit(true)
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}

                                                    </Box>
                                                </Box>
                                            </Grid>
                                        ) : data?.file_type === "png" || data?.file_type === "jpg" || data?.file_type === "jpeg" || data?.file_type === "gif" ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={2}
                                            >
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{ p: 2, mb: 2 }}
                                                    className={`card-media ${selectedMedia == data?.id && editMediaType === "file" && "selected-media-file"}`}
                                                >
                                                    <Box
                                                        height={174}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        className="img-container"
                                                    >
                                                        <Image
                                                            src={
                                                                data?.file_path
                                                            }
                                                            alt={data?.title}
                                                            className="media-img"
                                                        />
                                                        <Box class="middle">
                                                            <Box className="media-icons-div">
                                                                {data?.is_system == true && (
                                                                    <Box
                                                                        size="small"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faGears}
                                                                            color="#660C60"
                                                                            className="system-icon"
                                                                        />
                                                                    </Box>
                                                                )}
                                                                {data?.edit_media == true && (
                                                                    <Box
                                                                        size="small"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={faPen}
                                                                            color="#660C60"
                                                                            className="system-icon"
                                                                            onClick={() => {
                                                                                setEditMediType("file")
                                                                                setSelectedMedia(data?.id)
                                                                                setIsEdit(true)
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Divider />
                                                </Card>
                                            </Grid>
                                        ) : data?.file_type === "pdf" ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={2}
                                            >
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{
                                                        p: 2,
                                                        mb: 2,
                                                        position: "relative",
                                                        cursor: "pointer",
                                                    }}
                                                    className={`card-media ${selectedMedia == data?.id && editMediaType === "file" && "selected-media-file"}`}
                                                >
                                                    <Box
                                                        height={150}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faFilePdf
                                                            }
                                                            color="#660C60"
                                                            className="folder-icon"
                                                        />
                                                    </Box>
                                                    <Divider />
                                                    <Box
                                                        className="media-item-name"
                                                    >
                                                        {data?.title}
                                                    </Box>
                                                    <Box className="media-icons">
                                                        <Box className="media-icons-div">
                                                            {data?.is_system == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faGears}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                    />
                                                                </Box>
                                                            )}
                                                            {data?.edit_media == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faPen}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                        onClick={() => {
                                                                            setEditMediType("file")
                                                                            setSelectedMedia(data?.id)
                                                                            setIsEdit(true)
                                                                        }}
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        ) : data?.file_type === "docx" ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={2}
                                            >
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{
                                                        p: 2,
                                                        mb: 2,
                                                        position:
                                                            "relative",
                                                        cursor: "pointer",
                                                    }}
                                                    className={`card-media ${selectedMedia == data?.id && editMediaType === "file" && "selected-media-file"}`}
                                                >
                                                    <Box
                                                        height={150}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faFile
                                                            }
                                                            color="#660C60"
                                                            className="folder-icon"
                                                        />
                                                    </Box>
                                                    <Divider />
                                                    <Box
                                                        className="media-item-name"
                                                    >
                                                        {data?.title}
                                                    </Box>
                                                    <Box className="media-icons">
                                                        <Box className="media-icons-div">
                                                            {data?.is_system == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faGears}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                    />
                                                                </Box>
                                                            )}
                                                            {data?.edit_media == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faPen}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                        onClick={() => {
                                                                            setEditMediType("file")
                                                                            setSelectedMedia(data?.id)
                                                                            setIsEdit(true)
                                                                        }}
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        ) : data?.file_type === "pptx" ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={2}
                                            >
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{
                                                        p: 2,
                                                        mb: 2,
                                                        position:
                                                            "relative",
                                                        cursor: "pointer",
                                                    }}
                                                    className={`card-media ${selectedMedia == data?.id && editMediaType === "file" && "selected-media-file"}`}
                                                >
                                                    <Box
                                                        height={150}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faFile
                                                            }
                                                            color="#660C60"
                                                            className="folder-icon"
                                                        />
                                                    </Box>
                                                    <Divider />
                                                    <Box
                                                        className="media-item-name"
                                                    >
                                                        {data?.title}
                                                    </Box>
                                                    <Box className="media-icons">
                                                        <Box className="media-icons-div">
                                                            {data?.is_system == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faGears}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                    />
                                                                </Box>
                                                            )}
                                                            {data?.edit_media == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faPen}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                        onClick={() => {
                                                                            setEditMediType("file")
                                                                            setSelectedMedia(data?.id)
                                                                            setIsEdit(true)
                                                                        }}
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        ) : data?.file_type === "xlsx" ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={2}
                                            >
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{
                                                        p: 2,
                                                        mb: 2,
                                                        position:
                                                            "relative",
                                                        cursor: "pointer",
                                                    }}
                                                    className={`card-media ${selectedMedia == data?.id && editMediaType === "file" && "selected-media-file"}`}
                                                >
                                                    <Box
                                                        height={150}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faFileExcel
                                                            }
                                                            color="#660C60"
                                                            className="folder-icon"
                                                        />
                                                    </Box>
                                                    <Divider />
                                                    <Box
                                                        className="media-item-name"
                                                    >
                                                        {data?.title}
                                                    </Box>
                                                    <Box className="media-icons">
                                                        <Box className="media-icons-div">
                                                            {data?.is_system == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faGears}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                    />
                                                                </Box>
                                                            )}
                                                            {data?.edit_media == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faPen}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                        onClick={() => {
                                                                            setEditMediType("file")
                                                                            setSelectedMedia(data?.id)
                                                                            setIsEdit(true)
                                                                        }}
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        ) : data?.file_type === "mp4" ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={2}
                                            >
                                                <Card
                                                    key={index}
                                                    variant="outlined"
                                                    sx={{
                                                        p: 2,
                                                        mb: 2,
                                                        position:
                                                            "relative",
                                                        cursor: "pointer",
                                                    }}
                                                    className={`card-media ${selectedMedia == data?.id && editMediaType === "file" && "selected-media-file"}`}
                                                >
                                                    <Box
                                                        height={150}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faVideo
                                                            }
                                                            color="#660C60"
                                                            className="folder-icon"
                                                        />
                                                    </Box>
                                                    <Divider />
                                                    <Box
                                                        className="media-item-name"
                                                    >
                                                        {data?.title}
                                                    </Box>
                                                    <Box className="media-icons">
                                                        <Box className="media-icons-div">
                                                            {data?.is_system == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faGears}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                    />
                                                                </Box>
                                                            )}
                                                            {data?.edit_media == true && (
                                                                <Box
                                                                    size="small"
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faPen}
                                                                        color="#660C60"
                                                                        className="system-icon"
                                                                        onClick={() => {
                                                                            setEditMediType("file")
                                                                            setSelectedMedia(data?.id)
                                                                            setIsEdit(true)
                                                                        }}
                                                                    />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        ) : ""
                                    )}
                                    <Divider className="mt-4 mb-4" style={{ background: "#EEEEEE" }} />
                                </Grid>
                                {totalMedia > getAllMediaResponse?.meta?.per_page && (
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        className="type-pagination mt-5"
                                        onChange={handleChangePage}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{ previous: PreviousItem, next: NextItem }}
                                                {...item}
                                            />
                                        )}
                                    />
                                )}
                            </Box>
                        ) : (
                            <div className="no-data mt-5">
                                <div>
                                    <Image
                                        src={NoDataFoundImg}
                                        className="text-center mx-auto no-data-img mt-4"
                                    />
                                </div>
                                <Typography component="h5" variant="h5">
                                    No data found
                                </Typography>
                            </div>
                        )}
                    </Box>
                </Container>

                <AddFolder
                    openAddFolder={openAddFolder}
                    isEdit={isEdit}
                    handleCloseAddFolderModel={handleCloseAddFolderModel}
                    valueForFolderTab={valueForFolderTab}
                    handleChangeForFolder={handleChangeForFolder}
                    newFolderName={newFolderName}
                    setNewFolderName={setNewFolderName}
                    loggedUser={loggedUser}
                    isSystemTypeFolder={isSystemTypeFolder}
                    handleChangeForSystemFolder={handleChangeForSystemFolder}
                    isParentFolderSystemType={isParentFolderSystemType}
                    getAllGroupsAndUsersForFolder={getAllGroupsAndUsersForFolder}
                    permissionsForFolder={permissionsForFolder}
                    setPermissionsForFolder={setPermissionsForFolder}
                    isAdvancePermissionForFolder={isAdvancePermissionForFolder}
                    setIsAdvancePermissionForFolder={setIsAdvancePermissionForFolder}
                    columns_atttributes_headers={columns_atttributes_headers}
                    columns_advace_atttributes_headers={columns_advace_atttributes_headers}
                    handleToggleForFolder={handleToggleForFolder}
                    responseForFolder={responseForFolder}
                    storeFolder={storeFolder}
                />

                <AddMedia
                    openFileUploadModel={openFileUploadModel}
                    isEdit={isEdit}
                    handleCloseForMediaModel={handleCloseForMediaModel}
                    getRootProps={getRootProps}
                    selectedFile={selectedFile}
                    getInputProps={getInputProps}
                    renderPreview={renderPreview}
                    valueForMediaTab={valueForMediaTab}
                    handleChangeForMedia={handleChangeForMedia}
                    mediaTitle={mediaTitle}
                    setMediaTitle={setMediaTitle}
                    mediaAltText={mediaAltText}
                    setMediaAltText={setMediaAltText}
                    mediaDescription={mediaDescription}
                    setMediaDescription={setMediaDescription}
                    mediaDirectUrl={mediaDirectUrl}
                    setMediaDirectUrl={setMediaDirectUrl}
                    loggedUser={loggedUser}
                    isSystemTypeMedia={isSystemTypeMedia}
                    handleChangeForSystemMedia={handleChangeForSystemMedia}
                    isParentFolderSystemType={isParentFolderSystemType}
                    getAllGroupsAndUsersForMedia={getAllGroupsAndUsersForMedia}
                    permissionsForMedia={permissionsForMedia}
                    setPermissionsForMedia={setPermissionsForMedia}
                    isAdvancePermissionForMedia={isAdvancePermissionForMedia}
                    setIsAdvancePermissionForMedia={setIsAdvancePermissionForMedia}
                    columns_atttributes_headers={columns_atttributes_headers}
                    columns_advace_atttributes_headers={columns_advace_atttributes_headers}
                    handleToggleForMedia={handleToggleForMedia}
                    responseForMedia={responseForMedia}
                    storeMedia={storeMedia}
                    showSystemMediaCheckbox={true}
                />

                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default AdminMedia