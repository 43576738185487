import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import {
  faHouse,
  faUsers,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";
import { faMessage } from "@fortawesome/free-regular-svg-icons";

export const UserMenu = (props) => {
  let {
    selectedItem,
    open,
    openOperation,
    handleClickForOperationsMenu,
    dispatch,
    navigate,
    location,
    adminAccessible,
    getAllTypesForUsers,
    loggedUser
  } = props;
  return (
    <List className="list-item-menu">
      <ListItem
        key={"user-home"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/dashboard"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("user-home"));
            if (adminAccessible == true) {
              localStorage.setItem("last-redirect-page-admin-user", `/dashboard`)
            } else {
              localStorage.setItem("last-redirect-page-user", `/dashboard`)
            }
            localStorage.removeItem("selected-chat-room-id")
            navigate("/dashboard");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faHouse} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"media"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/media"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            dispatch(drawerMenuAction.handleNameCollapsable(false));
            dispatch(drawerMenuAction.handleTypesCollapsable(false));
            dispatch(drawerMenuAction.handleActiveType(false));
            dispatch(drawerMenuAction.clickDrawerItem("home"));
            if (adminAccessible == true) {
              localStorage.setItem("last-redirect-page-admin-user", `/media`)
            } else {
              localStorage.setItem("last-redirect-page-user", `/media`)
            }
            localStorage.removeItem("selected-chat-room-id")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            navigate("/media");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faFolder} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Media"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      {getAllTypesForUsers?.map((type, index) => (
        <ListItem
          key={type?.slug}
          disablePadding
          sx={{ display: "block" }}
          className={
            location.pathname === `/user-type/${type?.slug}` && type?.slug === location.pathname.split("/")[2]
              ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
              } `
              : "non-selected-menu-item w-48px"
          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => {
              if (loggedUser?.user_type == "admin" && adminAccessible == true) {

                localStorage.setItem(
                  "type-selected-name-user",
                  type?.name
                );
                localStorage.setItem(
                  "type-selected-id-user",
                  type?.id
                );
                localStorage.setItem(
                  "type-selected-slug-user",
                  type?.slug
                );
                localStorage.setItem("last-redirect-page-admin-user", `/user-type/${type?.slug}`)
                navigate(`/user-type/${type?.slug}`);
              }
              else {
                localStorage.setItem(
                  "type-selected-name-user",
                  type?.name
                );
                localStorage.setItem(
                  "type-selected-id-user",
                  type?.id
                );
                localStorage.setItem(
                  "type-selected-slug-user",
                  type?.slug
                );
                localStorage.setItem("last-redirect-page-user", `/user-type/${type?.slug}`)
                navigate(`/user-type/${type?.slug}`);
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
              }}
            >
              <FontAwesomeIcon icon={type?.icon} className="menu-icon" />
            </ListItemIcon>
            <ListItemText
              primary={type?.name}
              sx={{ opacity: open ? 1 : 0 }}
              className="menu-name" />
          </ListItemButton>
        </ListItem>
      ))}
      <ListItem
        key="people"
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === `/user-type/people`
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            if (loggedUser?.user_type === "admin" && adminAccessible == true) {
              localStorage.setItem(
                "type-selected-name-user",
                "People"
              );
              localStorage.setItem(
                "type-selected-id-user",
                "people"
              );
              localStorage.setItem("last-redirect-page-admin-user", `/user-type/people`)
            }

            else {
              localStorage.setItem(
                "type-selected-name-user",
                "People"
              );
              localStorage.setItem(
                "type-selected-id-user",
                "people"
              );
              localStorage.setItem(
                "type-selected-slug-user",
                "people"
              );
              localStorage.setItem("last-redirect-page-user", `/user-type/people`)
            }
            navigate(`/user-type/people`);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faUsers} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="People"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name" />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"user-chat"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/user-conversation"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            dispatch(drawerMenuAction.clickDrawerItem("user-chat"))
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            if (adminAccessible == true) {
              localStorage.setItem("last-redirect-page-admin-user", `/user-conversation`)
            } else {
              localStorage.setItem("last-redirect-page-user", `/user-conversation`)
            }
            navigate("/user-conversation");
          }
          }
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faMessage} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Conversations"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
