import React, { useEffect, useRef, useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faXmark,
    faFloppyDisk,
    faList,
    faPause,
    faPlay,
} from "@fortawesome/free-solid-svg-icons";
import {
    Box,
    Stack,
    Divider,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    Avatar,
    TableBody,
    Button,
    IconButton,
    Typography,
    TextField,
    Checkbox,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Grid
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "react-select";
import PropTypes from "prop-types";
import TableHeaderForSorting from "../TableHeaderForSorting";
import { AntSwitch } from "../switch/antSwitch";

function CustomTabPanel2(props) {
    const { children, value, className, index, errorTab, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box className="tab-box-3">{children}</Box>}
        </div>
    );
}

CustomTabPanel2.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const AddMedia = (props) => {
    let {
        openFileUploadModel,
        isEdit,
        handleCloseForMediaModel,
        getRootProps,
        selectedFile,
        getInputProps,
        renderPreview,
        valueForMediaTab,
        handleChangeForMedia,
        mediaTitle,
        setMediaTitle,
        mediaAltText,
        setMediaAltText,
        mediaDescription,
        setMediaDescription,
        mediaDirectUrl,
        setMediaDirectUrl,
        loggedUser,
        isSystemTypeMedia,
        handleChangeForSystemMedia,
        isParentFolderSystemType,
        getAllGroupsAndUsersForMedia,
        permissionsForMedia,
        setPermissionsForMedia,
        isAdvancePermissionForMedia,
        setIsAdvancePermissionForMedia,
        columns_atttributes_headers,
        columns_advace_atttributes_headers,
        handleToggleForMedia,
        responseForMedia,
        storeMedia,
        showSystemMediaCheckbox
    } = props

    function a11yProps4(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForMediaTab == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps5(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForMediaTab == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps6(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForMediaTab == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openFileUploadModel}
            maxWidth="sm"
            fullWidth={true}
            className="media-dialog"
        >
            <DialogTitle
                sx={{ m: 0, p: 2 }}
                id="customized-dialog-title"
                className="dialog-title"
            >
                {`${isEdit ? "Edit Media" : "Add Media"}`}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseForMediaModel}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                className="alert-close-btn"
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers className="media-preview">
                <Box className=" main-app-icon-div-4 d-flex flex-wrap">
                    <Box className="media-img-div">
                        <div
                            {...getRootProps({
                                className: `${selectedFile ? 'image-zone' : "dropzone"}`,
                                style: {
                                    textAlign: "center",
                                    cursor: "pointer",
                                    position: "relative",
                                    width: "100%", // Set a fixed width for your dropzone
                                    height: "100%", // Set a fixed height for your dropzone
                                },
                            })}
                        >
                            <input {...getInputProps()} />
                            {!selectedFile ? (
                                <p className="drag-drop-text">Drag 'n' drop a file here, or click to select one</p>
                            ) : (
                                renderPreview()
                            )}
                        </div>
                    </Box>
                    <Box className="media-info-div">
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                                className="box-attribute-2"
                            >
                                <Tabs
                                    value={valueForMediaTab}
                                    onChange={handleChangeForMedia}
                                    aria-label="basic tabs example"
                                    variant="scrollable"
                                    className={"tabs"}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#660C60",
                                        },
                                    }}
                                >
                                    <Tab
                                        label="Properties"
                                        icon={<FontAwesomeIcon icon={faList} />}
                                        iconPosition="start"
                                        {...a11yProps4(0)}
                                    />
                                    {/* <Tab
                                        label="Access"
                                        {...a11yProps5(1)}
                                        icon={<FontAwesomeIcon icon={faPlay} />}
                                        iconPosition="start"
                                    /> */}
                                    {isEdit && (
                                        <Tab
                                            label="Info"
                                            {...a11yProps6(2)}
                                            icon={<FontAwesomeIcon icon={faPause} />}
                                            iconPosition="start"
                                        />
                                    )}

                                </Tabs>
                            </Box>
                            <CustomTabPanel2 value={0} index={valueForMediaTab}>
                                <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                                    <Typography className="fs-14">Title</Typography>
                                    <TextField
                                        className="input-field mt-2"
                                        fullWidth
                                        id="outlined-basic short summary"
                                        variant="outlined"
                                        placeholder={`write media title`}
                                        value={mediaTitle}
                                        onChange={(event) => setMediaTitle(event?.target?.value)}
                                    />
                                    <Typography className="fs-14 mt-4">Alt Text</Typography>
                                    <TextField
                                        className="input-field mt-2"
                                        fullWidth
                                        id="outlined-basic short summary"
                                        variant="outlined"
                                        placeholder={`write alt text`}
                                        value={mediaAltText}
                                        onChange={(event) => setMediaAltText(event?.target?.value)}
                                    />
                                    <Typography className="fs-14 mt-4">Description</Typography>
                                    <TextField
                                        className="input-field mt-2"
                                        fullWidth
                                        multiline
                                        id="outlined-basic short summary"
                                        variant="outlined"
                                        placeholder={`write media description`}
                                        value={mediaDescription}
                                        onChange={(event) => setMediaDescription(event?.target?.value)}
                                        maxRows={"4"}
                                    />
                                    {isEdit == true && (
                                        <>
                                            <Typography className="fs-14 mt-4">Direct URL</Typography>
                                            <Box className="d-flex gap-1">
                                                <TextField
                                                    className="input-field mt-2"
                                                    fullWidth
                                                    id="outlined-basic short summary"
                                                    variant="outlined"
                                                    placeholder={`write media direct url`}
                                                    value={mediaDirectUrl}
                                                    disabled
                                                    onChange={(event) => setMediaDirectUrl(event?.target?.value)}
                                                />
                                                <Button
                                                    variant="contained"
                                                    className="btn mt-2 p-0 icon-btn"
                                                    onClick={() => {
                                                        // Create an invisible link and trigger download
                                                        const link = document.createElement('a');
                                                        link.href = mediaDirectUrl;
                                                        link.setAttribute('download', 'media-file'); // Optional: you can specify a file name
                                                        document.body.appendChild(link);
                                                        link.click();
                                                        document.body.removeChild(link); // Clean up after download
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                    {loggedUser?.user_type === "admin" && showSystemMediaCheckbox && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        isSystemTypeMedia === true
                                                    }
                                                    name="showOnUserPage"
                                                    onChange={handleChangeForSystemMedia}
                                                    disabled={isParentFolderSystemType}
                                                />
                                            }
                                            label="System type"
                                            className="font-inter mt-2"
                                        />
                                    )}
                                </Paper>
                            </CustomTabPanel2>

                            {/* <CustomTabPanel2 value={1} index={valueForMediaTab}>
                                <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                                    <Row className="row-media-access">
                                        <Col lg="12" className="">
                                            <Typography className="fw-400 fs-14 dark-text">
                                                Select Users
                                            </Typography>
                                            <Select
                                                closeMenuOnSelect={false}
                                                options={getAllGroupsAndUsersForMedia}
                                                menuPlacement="bottom"
                                                name="users"
                                                value={permissionsForMedia}
                                                placeholder="Select users..."
                                                isMulti
                                                menuPosition="fixed"
                                                onChange={(selectedOption) => {
                                                    const users = selectedOption.map(
                                                        (option) => option
                                                    );
                                                    setPermissionsForMedia(users);
                                                }}
                                                className={`muilt-select-field mt-2 `}
                                                classNamePrefix="select"
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        backgroundColor: state.isSelected
                                                            ? "#660c605c"
                                                            : provided.backgroundColor,
                                                        color: state.isSelected
                                                            ? "var(--dark-color)"
                                                            : provided.color,
                                                    }),
                                                    multiValueLabel: (provided, state) => {
                                                        return {
                                                            ...provided,
                                                            color:
                                                                state.data?.type === "group"
                                                                    ? "rgb(0, 135, 90)"
                                                                    : "rgb(0, 82, 204)",
                                                        };
                                                    },
                                                }}
                                            />
                                        </Col>
                                        <Col lg="12" className="repeatable-col">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isAdvancePermissionForMedia == true}
                                                        name="allowEdit"
                                                        onChange={() =>
                                                            setIsAdvancePermissionForMedia(
                                                                !isAdvancePermissionForMedia
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Show Advanced Permissions"
                                                className="font-inter fs-14"
                                            />
                                        </Col>
                                        <Col lg="12" className="repeatable-col">
                                            <TableContainer className="z-0">
                                                <Table
                                                    aria-labelledby="tableTitle"
                                                    stickyHeader
                                                >
                                                    <TableHeaderForSorting
                                                        columns={
                                                            isAdvancePermissionForMedia == false
                                                                ? columns_atttributes_headers
                                                                : columns_advace_atttributes_headers
                                                        }
                                                    />
                                                    <TableBody>
                                                        {permissionsForMedia?.length > 0 && (
                                                            <>
                                                                {permissionsForMedia?.map(
                                                                    (permission, index) => (
                                                                        <>
                                                                            <TableRow
                                                                                hover
                                                                                role="checkbox"
                                                                                tabIndex={-1}
                                                                                key={permission?.value}
                                                                                className="cursor-pointer tbl-row-attribute-row"
                                                                            >
                                                                                <TableCell
                                                                                    align="left"
                                                                                    className={` tbl-border-left tbl-cell-attribute-2`}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                    }}
                                                                                >
                                                                                    <Typography className="fs-14">
                                                                                        {permission?.label}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell
                                                                                    align="right"
                                                                                    className={`tbl-cell-attribute-2`}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                    }}
                                                                                >
                                                                                    <AntSwitch
                                                                                        name="view"
                                                                                        checked={
                                                                                            permission?.view
                                                                                        }
                                                                                        onChange={() =>
                                                                                            handleToggleForMedia(
                                                                                                permission?.value,
                                                                                                "view"
                                                                                            )
                                                                                        }
                                                                                        inputProps={{
                                                                                            "aria-label":
                                                                                                "ant design",
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    className={`tbl-cell-attribute-2`}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                    }}
                                                                                >
                                                                                    <AntSwitch
                                                                                        name="deny"
                                                                                        checked={
                                                                                            permission?.deny
                                                                                        }
                                                                                        onChange={() =>
                                                                                            handleToggleForMedia(
                                                                                                permission?.value,
                                                                                                "deny"
                                                                                            )
                                                                                        }
                                                                                        inputProps={{
                                                                                            "aria-label":
                                                                                                "ant design",
                                                                                        }}
                                                                                    />
                                                                                </TableCell>

                                                                                {isAdvancePermissionForMedia && (
                                                                                    <>
                                                                                        <TableCell
                                                                                            align="right"
                                                                                            className={`tbl-cell-attribute-2`}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                            }}
                                                                                        >
                                                                                            <AntSwitch
                                                                                                name="edit"
                                                                                                checked={
                                                                                                    permission?.edit
                                                                                                }
                                                                                                onChange={() =>
                                                                                                    handleToggleForMedia(
                                                                                                        permission?.value,
                                                                                                        "edit"
                                                                                                    )
                                                                                                }
                                                                                                inputProps={{
                                                                                                    "aria-label":
                                                                                                        "ant design",
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            align="right"
                                                                                            className={`tbl-cell-attribute-2`}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                            }}
                                                                                        >
                                                                                            <AntSwitch
                                                                                                name="delete"
                                                                                                checked={
                                                                                                    permission?.delete
                                                                                                }
                                                                                                onChange={() =>
                                                                                                    handleToggleForMedia(
                                                                                                        permission?.value,
                                                                                                        "delete"
                                                                                                    )
                                                                                                }
                                                                                                inputProps={{
                                                                                                    "aria-label":
                                                                                                        "ant design",
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            align="right"
                                                                                            className={`tbl-border-right tbl-cell-attribute-2`}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                            }}
                                                                                        >
                                                                                            <AntSwitch
                                                                                                name="redacted"
                                                                                                checked={
                                                                                                    permission?.redacted
                                                                                                }
                                                                                                onChange={() =>
                                                                                                    handleToggleForMedia(
                                                                                                        permission?.value,
                                                                                                        "redacted"
                                                                                                    )
                                                                                                }
                                                                                                inputProps={{
                                                                                                    "aria-label":
                                                                                                        "ant design",
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                    </>
                                                                                )}
                                                                            </TableRow>
                                                                            <TableRow className="extra-row"></TableRow>
                                                                        </>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Col>
                                    </Row>
                                </Paper>

                            </CustomTabPanel2> */}

                            <CustomTabPanel2 value={1} index={valueForMediaTab}>
                                <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                                    <List container spacing={2}>
                                        <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                            <ListItemText className="fs-14-muted">
                                                Upload Date
                                            </ListItemText>
                                            <ListItemText className="fs-14 text-end">
                                                {responseForMedia?.created_at}
                                            </ListItemText>
                                        </ListItem>
                                        <Divider component="li" className="divider-details" />

                                        <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                            <ListItemText className="fs-14-muted">
                                                Modified On
                                            </ListItemText>
                                            <ListItemText className="fs-14 text-end">
                                                {responseForMedia?.updated_at}
                                            </ListItemText>
                                        </ListItem>
                                        <Divider component="li" className="divider-details" />

                                        <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                            <ListItemText className="fs-14-muted">
                                                file Size
                                            </ListItemText>
                                            <ListItemText className="fs-14 text-end">
                                                {responseForMedia?.file_size}
                                            </ListItemText>
                                        </ListItem>
                                        <Divider component="li" className="divider-details" />

                                        <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                            <ListItemText className="fs-14-muted">
                                                Width
                                            </ListItemText>
                                            <ListItemText className="fs-14 text-end">
                                                {responseForMedia?.width}
                                            </ListItemText>
                                        </ListItem>
                                        <Divider component="li" className="divider-details" />

                                        <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                            <ListItemText className="fs-14-muted">
                                                Height
                                            </ListItemText>
                                            <ListItemText className="fs-14 text-end">
                                                {responseForMedia?.heigth}
                                            </ListItemText>
                                        </ListItem>
                                        <Divider component="li" className="divider-details" />

                                        <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                            <ListItemText className="fs-14-muted">
                                                Created By
                                            </ListItemText>
                                            <Box>
                                                <Stack
                                                    direction="row"
                                                    alignContent="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                    className="z-n1"
                                                >
                                                    <Avatar
                                                        src={responseForMedia?.created_by?.thumbnail}
                                                        className="author-avtar-details"
                                                    >
                                                        {responseForMedia?.created_by?.name?.charAt(0)}
                                                    </Avatar>
                                                    <Typography className="fw-400 fs-14">
                                                        {responseForMedia?.created_by?.name}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </ListItem>
                                        <Divider component="li" className="divider-details" />
                                    </List>
                                </Paper>
                            </CustomTabPanel2>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                <Button
                    className="btn delete-btn-model"
                    onClick={handleCloseForMediaModel}
                >
                    <FontAwesomeIcon icon={faXmark} />
                    Cancel
                </Button>
                <Button
                    className="btn primary-btn"
                    onClick={storeMedia}
                >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddMedia