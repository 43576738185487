import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import Collapse from "@mui/material/Collapse";
import {
  faHouse,
  faGears,
  faUsers,
  faFile,
  faList,
  faEnvelopeOpen,
  faStar,
  faChevronDown,
  faChevronUp,
  faGear,
  faUser,
  faUserGroup,
  faMessage,
  faFolder
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";

export const AdminMenu = (props) => {
  let {
    selectedItem,
    open,
    openNamingClassification,
    handleClickForNamingClassificationMenu,
    dispatch,
    navigate,
    location,
    getAllTypes,
    setOpenNamingClassification,
    openTypes,
    handleClickForTypesMenu,
    activeType,
    setOpenTypes,
    handleClickForUsersMenu,
    openUsers,
    setOpenUsers,
  } = props;
  return (
    <List className="list-item-menu">
      {open == true && (
        <ListItem>
          <div className="d-flex flex-wrpa gap-2 admin-view-div">
            <div className=" admin-view-div-icon">
              <FontAwesomeIcon icon={faGear} />
            </div>
            <div className="admin-view-div-p">
              <p className="text-wrap ">
                You are currently viewing platform in administrative mode.
              </p>
            </div>
          </div>
        </ListItem>
      )}

      <ListItem
        key={"home"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            dispatch(drawerMenuAction.handleNameCollapsable(false));
            dispatch(drawerMenuAction.handleTypesCollapsable(false));
            dispatch(drawerMenuAction.handleActiveType(false));
            dispatch(drawerMenuAction.clickDrawerItem("home"));
            localStorage.setItem("last-redirect-page", "/");
            localStorage.removeItem("selected-chat-room-id")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            navigate("/");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faHouse} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"media"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/media"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            dispatch(drawerMenuAction.handleNameCollapsable(false));
            dispatch(drawerMenuAction.handleTypesCollapsable(false));
            dispatch(drawerMenuAction.handleActiveType(false));
            dispatch(drawerMenuAction.clickDrawerItem("home"));
            localStorage.setItem("last-redirect-page", "/media");
            localStorage.removeItem("selected-chat-room-id")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            navigate("/media");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faFolder} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Media"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"system"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/system"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            }`
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            dispatch(drawerMenuAction.handleNameCollapsable(false));
            dispatch(drawerMenuAction.handleTypesCollapsable(false));
            dispatch(drawerMenuAction.handleActiveType(false));
            dispatch(drawerMenuAction.clickDrawerItem("system"));
            localStorage.removeItem("selected-chat-room-id")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            localStorage.setItem("last-redirect-page", "/system");

            navigate("/system");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faGears}
              className="menu-icon"
              style={{
                color: "#660C60",
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary="System"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"operations2"}
        disablePadding
        sx={{ display: "block" }}
        className={
          activeType == true ||
            location.pathname.startsWith("/type/") ||
            location.pathname === "/create-content"
            ? `selected-menu-item w-48px  ${open === false && "open-selected-menu"
            }`
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")

            dispatch(drawerMenuAction.handleTypesCollapsable(true));

            dispatch(drawerMenuAction.handleActiveType(true));
            dispatch(drawerMenuAction.handleNameCollapsable(false));

            handleClickForTypesMenu();
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
            className={`${open === false && "list-icon-root-false-2"}`}
          >
            <FontAwesomeIcon icon={faList} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Types"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
          {open === true && (
            <>
              {openTypes ? (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="menu-icon"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="menu-icon"
                />
              )}
            </>
          )}
        </ListItemButton>
      </ListItem>
      <Collapse
        in={openTypes}
        timeout="auto"
        unmountOnExit
        className={`${open === true && "p-1"}`}
      >
        <List component="div" disablePadding>
          {getAllTypes?.map((type, index) => (
            <ListItem
              key={type?.slug}
              disablePadding
              sx={{ display: "block" }}
              className={
                location.pathname === `/type/${type?.slug}` ||
                  location.pathname === `/type/add/${type?.slug}` ||
                  location.pathname === `/type/edit/${type?.slug}/${type?.id}` ||
                  location.pathname === `/type/view/${type?.slug}/${type?.id}` ||
                  location.pathname.startsWith(`/type/edit/${type?.slug}`)
                  ? `selected-menu-item w-48px  ${open === false && "open-selected-menu"
                  }`
                  : "non-selected-menu-item w-48px"
              }
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  localStorage.removeItem("selected-folder-item")

                  dispatch(drawerMenuAction.clickDrawerItem(type?.slug));
                  setOpenTypes(true);
                  dispatch(drawerMenuAction.handleNameCollapsable(false));
                  dispatch(drawerMenuAction.handleActiveType(true));
                  dispatch(drawerMenuAction.handleTypesCollapsable(true));
                  dispatch(drawerMenuAction.handleUsersCollapsable(false));

                  localStorage.setItem(
                    "last-redirect-page",
                    `/type/${type?.slug}`
                  );
                  localStorage.setItem("type-selected-name", type?.name);
                  localStorage.setItem("type-selected-id", type?.id);
                  localStorage.setItem("type-selected-slug", type?.slug);
                  localStorage.removeItem("selected-chat-room-id")
                  localStorage.removeItem("globalSearchTab")
                  localStorage.removeItem("globalSearchText")
                  navigate(`/type/${type?.slug}`);
                }}
                className={open === false && "sub-menu-for-close"}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    ml: 4,
                    justifyContent: "center",
                  }}
                  className={`${open === false && "sub-menu-icon-false"}`}
                >
                  <FontAwesomeIcon
                    icon={type?.icon !== null ? type?.icon : faStar}
                    className="menu-icon-2"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={type?.name}
                  className="menu-name"
                  sx={{
                    opacity: open ? 1 : 0,
                    color: type?.colour,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>

      <ListItem
        key={"users"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/settings" ||
            location.pathname === "/profile-fields" ||
            location.pathname === "/users" ||
            location.pathname === "/groups" ||
            location.pathname === "/add/groups" ||
            location.pathname.startsWith("/group/edit/") ||
            location.pathname === "/add/users" ||
            location.pathname.startsWith("/user/edit/")
            ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
            }`
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")

            dispatch(drawerMenuAction.handleTypesCollapsable(false));
            dispatch(drawerMenuAction.handleActiveType(false));
            dispatch(drawerMenuAction.handleTypesCollapsable(false));

            dispatch(drawerMenuAction.handleActiveType(false));

            handleClickForUsersMenu();
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
            className={`${open === false && "list-icon-root-false"}`}
          >
            <FontAwesomeIcon icon={faUsers} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Users"
            sx={{ opacity: open ? 1 : 0 }}
            className={` ${open === false ? "menu-name-false" : "menu-name"}`}
          />
          {open === true && (
            <>
              {openUsers === true ? (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="menu-icon"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="menu-icon"
                />
              )}
            </>
          )}
        </ListItemButton>
      </ListItem>
      <Collapse
        in={openUsers}
        timeout="auto"
        unmountOnExit
        className={`${open === true && "p-1"}`}
      >
        <List component="div" disablePadding>
          <ListItem
            key={"users-settings"}
            disablePadding
            sx={{ display: "block" }}
            className={
              location.pathname === "/settings"
                ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
                }`
                : "non-selected-menu-item w-48px"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("selected-folder-item")

                dispatch(drawerMenuAction.clickDrawerItem("users-settings"));
                setOpenTypes(false);
                dispatch(drawerMenuAction.handleNameCollapsable(false));
                dispatch(drawerMenuAction.handleActiveType(false));
                dispatch(drawerMenuAction.handleUsersCollapsable(true));
                setOpenUsers(true);
                localStorage.setItem("last-redirect-page", `/settings`);
                localStorage.removeItem("selected-chat-room-id")
                localStorage.removeItem("globalSearchTab")
                localStorage.removeItem("globalSearchText")
                navigate("/settings");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  ml: 4,
                  justifyContent: "center",
                }}
                className={`${open === false && "sub-menu-icon-false"}`}
              >
                <FontAwesomeIcon icon={faGear} className="menu-icon" />
              </ListItemIcon>
              <ListItemText
                primary="Settings"
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"users-profile-fields"}
            disablePadding
            sx={{ display: "block" }}
            className={
              location.pathname === "/profile-fields"
                ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
                }`
                : "non-selected-menu-item w-48px"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("selected-folder-item")

                dispatch(
                  drawerMenuAction.clickDrawerItem("users-profile-fields")
                );
                setOpenTypes(false);
                dispatch(drawerMenuAction.handleNameCollapsable(false));
                dispatch(drawerMenuAction.handleActiveType(false));
                dispatch(drawerMenuAction.handleUsersCollapsable(true));

                setOpenUsers(true);
                localStorage.setItem("last-redirect-page", `/profile-fields`);
                localStorage.removeItem("selected-chat-room-id")
                localStorage.removeItem("globalSearchTab")
                localStorage.removeItem("globalSearchText")
                navigate("/profile-fields");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  ml: 4,
                  justifyContent: "center",
                }}
                className={`${open === false && "sub-menu-icon-false"}`}
              >
                <FontAwesomeIcon icon={faUser} className="menu-icon" />
              </ListItemIcon>
              <ListItemText
                primary="Profile Fields"
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"users-users"}
            disablePadding
            sx={{ display: "block" }}
            className={
              location.pathname === "/users" ||
                location.pathname === "/add/users" ||
                location.pathname.startsWith("/user/edit/")
                ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
                }`
                : "non-selected-menu-item w-48px"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("selected-folder-item")

                dispatch(drawerMenuAction.clickDrawerItem("users-users"));
                setOpenTypes(false);
                dispatch(drawerMenuAction.handleNameCollapsable(false));
                dispatch(drawerMenuAction.handleActiveType(false));
                dispatch(drawerMenuAction.handleUsersCollapsable(true));
                localStorage.removeItem("selected-chat-room-id")

                setOpenUsers(true);
                localStorage.setItem("last-redirect-page", `/users`);
                localStorage.removeItem("globalSearchTab")
                localStorage.removeItem("globalSearchText")
                navigate("/users");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  ml: 4,
                  justifyContent: "center",
                }}
                className={`${open === false && "sub-menu-icon-false"}`}
              >
                <FontAwesomeIcon icon={faUsers} className="menu-icon" />
              </ListItemIcon>
              <ListItemText
                primary="Users"
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"users-groups"}
            disablePadding
            sx={{ display: "block" }}
            className={
              location.pathname === "/groups" ||
                location.pathname === "/add/groups" ||
                location.pathname.startsWith("/group/edit/")
                ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
                }`
                : "non-selected-menu-item w-48px"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("selected-folder-item")

                dispatch(drawerMenuAction.clickDrawerItem("users-groups"));
                setOpenTypes(false);
                dispatch(drawerMenuAction.handleNameCollapsable(false));
                dispatch(drawerMenuAction.handleActiveType(false));
                dispatch(drawerMenuAction.handleUsersCollapsable(true));

                setOpenUsers(true);
                localStorage.setItem("last-redirect-page", `/groups`);
                localStorage.removeItem("selected-chat-room-id")
                localStorage.removeItem("globalSearchTab")
                localStorage.removeItem("globalSearchText")
                navigate("/groups");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  ml: 4,
                  justifyContent: "center",
                }}
                className={`${open === false && "sub-menu-icon-false"}`}
              >
                <FontAwesomeIcon icon={faUserGroup} className="menu-icon" />
              </ListItemIcon>
              <ListItemText
                primary="Groups"
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        key={"forms"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/forms"
            ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
            }`
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")

            dispatch(drawerMenuAction.handleNameCollapsable(false));
            dispatch(drawerMenuAction.handleTypesCollapsable(false));
            dispatch(drawerMenuAction.handleActiveType(false));
            dispatch(drawerMenuAction.clickDrawerItem("forms"));
            localStorage.setItem("last-redirect-page", `/forms`);
            localStorage.removeItem("selected-chat-room-id")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            navigate("/forms");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faEnvelopeOpen} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Forms"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"naming"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/types" ||
            location.pathname === "/types/add-new" ||
            location.pathname === "/attribute" ||
            location.pathname === "/attribute/add-new" ||
            location.pathname.startsWith("/types") ||
            location.pathname.startsWith("/attribute") ||
            location.pathname === "/add/categories" ||
            location.pathname === "/categories" ||
            location.pathname.startsWith("/categories/edit")
            ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
            }`
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")

            dispatch(drawerMenuAction.handleTypesCollapsable(false));
            dispatch(drawerMenuAction.handleActiveType(false));
            handleClickForNamingClassificationMenu();
            setOpenTypes(false);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
            className={`${open === false && "list-icon-root-false"}`}
          >
            <FontAwesomeIcon icon={faFile} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Naming & Classification"
            sx={{ opacity: open ? 1 : 0 }}
            className={` ${open === false ? "menu-name-false" : "menu-name"}`}
          />
          {open === true && (
            <>
              {openNamingClassification === true ? (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="menu-icon"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="menu-icon"
                />
              )}
            </>
          )}
        </ListItemButton>
      </ListItem>
      <Collapse
        in={openNamingClassification}
        timeout="auto"
        unmountOnExit
        className={`${open === true && "p-1"}`}
      >
        <List component="div" disablePadding>
          <ListItem
            key={"naming-types"}
            disablePadding
            sx={{ display: "block" }}
            className={
              location.pathname === "/types" ||
                location.pathname === "/types/add-new" ||
                location.pathname.startsWith("/types/edit/")
                ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
                }`
                : "non-selected-menu-item w-48px"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("selected-folder-item")

                dispatch(drawerMenuAction.clickDrawerItem("naming-types"));
                setOpenTypes(false);
                dispatch(drawerMenuAction.handleNameCollapsable(true));
                dispatch(drawerMenuAction.handleActiveType(false));
                dispatch(drawerMenuAction.handleUsersCollapsable(false));

                setOpenNamingClassification(true);
                localStorage.setItem("last-redirect-page", `/types`);
                localStorage.removeItem("selected-chat-room-id")
                localStorage.removeItem("globalSearchTab")
                localStorage.removeItem("globalSearchText")
                navigate("/types");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  ml: 4,
                  justifyContent: "center",
                }}
                className={`${open === false && "sub-menu-icon-false"}`}
              >
                <FontAwesomeIcon icon={faList} className="menu-icon" />
              </ListItemIcon>
              <ListItemText
                primary="Types"
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"naming-categories"}
            disablePadding
            sx={{ display: "block" }}
            className={
              location.pathname === "/add/categories" ||
                location.pathname === "/categories" ||
                location.pathname.startsWith("/categories/edit")
                ? `selected-menu-item w-48px  ${open === false && "open-selected-menu"
                }`
                : "non-selected-menu-item w-48px"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("selected-folder-item")

                dispatch(drawerMenuAction.handleActiveType(false));
                setOpenTypes(false);
                dispatch(drawerMenuAction.clickDrawerItem("naming-categories"));
                dispatch(drawerMenuAction.handleNameCollapsable(true));
                dispatch(drawerMenuAction.handleUsersCollapsable(false));

                setOpenNamingClassification(true);
                localStorage.setItem("last-redirect-page", `/categories`);
                localStorage.removeItem("selected-chat-room-id")
                localStorage.removeItem("globalSearchTab")
                localStorage.removeItem("globalSearchText")
                navigate("/categories");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  ml: 4,
                  justifyContent: "center",
                }}
                className={`${open === false && "sub-menu-icon-false"}`}
              >
                <FontAwesomeIcon icon={faGears} className="menu-icon" />
              </ListItemIcon>
              <ListItemText
                primary="Categories"
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"naming-attributes"}
            disablePadding
            sx={{ display: "block" }}
            className={
              location.pathname === "/attribute/add-new" ||
                location.pathname === "/attribute" ||
                location.pathname.startsWith("/attribute/edit")
                ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
                }`
                : "non-selected-menu-item w-48px"
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                localStorage.removeItem("selected-folder-item")

                dispatch(drawerMenuAction.handleActiveType(false));
                setOpenTypes(false);

                dispatch(drawerMenuAction.clickDrawerItem("naming-attributes"));
                dispatch(drawerMenuAction.handleNameCollapsable(true));
                dispatch(drawerMenuAction.handleUsersCollapsable(false));

                setOpenNamingClassification(true);
                localStorage.setItem("last-redirect-page", `/attribute`);
                localStorage.removeItem("selected-chat-room-id")
                localStorage.removeItem("globalSearchTab")
                localStorage.removeItem("globalSearchText")
                navigate("/attribute");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  ml: 4,
                  justifyContent: "center",
                }}
                className={`${open === false && "sub-menu-icon-false"}`}
              >
                <FontAwesomeIcon icon={faHouse} className="menu-icon" />
              </ListItemIcon>
              <ListItemText
                primary="Attributes"
                className="menu-name"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

        </List>
      </Collapse>
      <ListItem
        key={"admin-user-chat"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/admin-conversation"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            dispatch(drawerMenuAction.clickDrawerItem("admin-user-chat"))
            localStorage.setItem("last-redirect-page", `/admin-conversation`)
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            navigate("/admin-conversation");
          }
          }
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faMessage} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Conversations"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
